import { apiService, ApiService } from '../api/apiService';
import { Project, ProjectWithPages } from './dto/project';

export class ProjectsService {
    constructor(private apiService: ApiService) {}

    getAllProjects(signal?: AbortSignal): Promise<Project[]> {
        return this.apiService.get('projects', { signal });
    }

    getProjectById(id: number, signal?: AbortSignal): Promise<ProjectWithPages> {
        return this.apiService.get(`projects/${id}`, { signal });
    }

    deleteProjectById(id: number, signal?: AbortSignal): Promise<undefined> {
        return this.apiService.delete(`projects/${id}`, { signal });
    }

    updateProject(project: ProjectWithPages): Promise<ProjectWithPages> {
        return this.apiService.patch(`projects/${project.id}`, { body: project });
    }
}

export const projectsService = new ProjectsService(apiService);
