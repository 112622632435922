import React from 'react';
import { Divider, Typography } from 'antd';
const { Title, Text } = Typography;

export interface FrequentlyAskedQuestion {
    question: string;
    answer: string;
}

export const FrequentAskedQuestions: React.FC<{ questions: FrequentlyAskedQuestion[]; description?: string }> = ({ questions, description = '' }) => {
    return (
        <>
            <Title level={1}>Frequent Asked Questions</Title>
            {description && <Text style={{ whiteSpace: 'pre-wrap' }}>{description}</Text>}
            {questions.map((frequentlyAskedQuestion, index) => {
                return (
                    <div key={index + 1}>
                        <Title level={2}>{frequentlyAskedQuestion.question}</Title>
                        <Text>{frequentlyAskedQuestion.answer}</Text>
                        <Divider />
                    </div>
                );
            })}
        </>
    );
};
