import React, { useEffect } from 'react';
import { RouteObject } from 'react-router';
import { createBrowserRouter, Navigate, Outlet, RouterProvider, useLocation } from 'react-router-dom';
import { NotFoundPage } from './pages/NotFoundPage/NotFoundPage';
import { HomePage } from './pages/HomePage/HomePage';
import { LoginPage } from './pages/Auth/Login/LoginPage';
import { RegistrationPage } from './pages/Auth/Registration/RegistrationPage';
import { useAuth } from './context/AuthContext';
import { ResetPasswordPage } from './pages/Auth/ResetPassword/ResetPasswordPage';
import { ChangePasswordPage } from './pages/Auth/ChangePassword/ChangePasswordPage';
import { ConfirmEmailPage } from './pages/Auth/ConfirmEmail/ConfirmEmailPage';
import { User, UserRole, useUser } from './context/UserContext';
import { TrialPage } from './pages/TrialPage/TrialPage';
import { LinkedInCallback } from 'react-linkedin-login-oauth2';
import { LandingPage } from './pages/Static/LandingPage/LandingPage';
import { FAQPage } from './pages/Static/FAQPage/FAQPage';
import { UnAuthLayout } from './components/Layouts/UnAuthLayout/UnAuthLayout';
import { StaticContentLayout } from './components/Layouts/StaticContentLayout/StaticContentLayout';
import { AnalysesStatisticPage } from './pages/Admin/AnalysesStatisticPage/AnalysesStatisticPage';
import { AuthLayout } from './components/Layouts/AuthLayout/AuthLayout';
import { TermsAndConditionsPage } from './pages/Static/TermsAndConditionsPage/TermsAndConditionsPage';
import { PrivacyPolicyPage } from './pages/Static/PrivacyPolicyPage/PrivacyPolicyPage';
import { FeedbackStatisticPage } from './pages/Admin/FeedbackStatisticPage/FeedbackStatisticPage';

export const AppRouter: React.FC = () => {
    const { access_token } = useAuth();
    const user: User | null = useUser();
    const userIsAuth = !!access_token;

    const SharedRoutesWrapper: React.FC = () => {
        let location = useLocation();
        useEffect(() => {
            window.scrollTo(0, 0);
        }, [location]);
        return <Outlet />;
    };

    const authUserRoutes: RouteObject[] = [
        {
            element: <SharedRoutesWrapper />,
            children: [
                {
                    path: '/',
                    element: <AuthLayout />,
                    children: [
                        {
                            index: true,
                            element: user?.role === UserRole.FreeTrialUser ? <TrialPage /> : <HomePage />,
                        },
                    ],
                },
                {
                    element: <AuthLayout includeFooter={true} />,
                    children: [
                        {
                            path: '/faq',
                            element: <FAQPage />,
                        },
                        {
                            path: '/terms-and-conditions',
                            element: <TermsAndConditionsPage />,
                        },
                        {
                            path: '/privacy-policy',
                            element: <PrivacyPolicyPage />,
                        },
                        {
                            path: '/statistic/',
                            element: <Outlet />,
                            children: [
                                {
                                    path: 'analyses',
                                    element: user?.role !== UserRole.FreeTrialUser ? <AnalysesStatisticPage /> : <Navigate to="/" />,
                                },
                                {
                                    path: 'feedbacks',
                                    element: user?.role !== UserRole.FreeTrialUser ? <FeedbackStatisticPage /> : <Navigate to="/" />,
                                },
                            ],
                        },
                    ],
                },
                {
                    path: '/login',
                    element: <Navigate to="/" />,
                },
                {
                    path: '/registration',
                    element: <Navigate to="/" />,
                },
                {
                    path: '/change-password',
                    element: <Navigate to="/" />,
                },
                {
                    path: '/confirm-email',
                    element: <Navigate to="/" />,
                },
                {
                    path: '*',
                    element: <NotFoundPage />,
                },
            ],
        },
    ];

    const unAuthUserRoutes: RouteObject[] = [
        {
            element: <SharedRoutesWrapper />,
            children: [
                {
                    element: <UnAuthLayout />,
                    children: [
                        {
                            path: '/login',
                            element: <LoginPage />,
                        },
                        {
                            path: '/registration',
                            element: <RegistrationPage />,
                        },
                        {
                            path: '/reset-password',
                            element: <ResetPasswordPage />,
                        },
                        {
                            path: '/change-password',
                            element: <ChangePasswordPage />,
                        },
                        {
                            path: '/confirm-email',
                            element: <ConfirmEmailPage />,
                        },
                        {
                            path: '/linkedin',
                            element: <LinkedInCallback />,
                        },
                        {
                            path: '*',
                            element: <Navigate to="/login" />,
                        },
                    ],
                },
                {
                    element: <StaticContentLayout />,
                    children: [
                        {
                            path: '/',
                            element: <LandingPage />,
                        },
                        {
                            path: '/faq',
                            element: <FAQPage />,
                        },
                        {
                            path: '/terms-and-conditions',
                            element: <TermsAndConditionsPage />,
                        },
                        {
                            path: '/privacy-policy',
                            element: <PrivacyPolicyPage />,
                        },
                    ],
                },
            ],
        },
    ];

    const sharedRoutes: RouteObject[] = [];

    const allRoutes: RouteObject[] = userIsAuth ? [...authUserRoutes, ...sharedRoutes] : [...unAuthUserRoutes, ...sharedRoutes];

    const appRouter = createBrowserRouter(allRoutes);

    return <RouterProvider router={appRouter} />;
};
