import io, { Socket } from 'socket.io-client';
import { socketUrl } from '../App.config';
import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { message } from 'antd';
import { useAuth } from './AuthContext';

export const WsConnectionContext = React.createContext<Socket | null>(null);

export const useWsConnection = () => {
    return useContext<Socket | null>(WsConnectionContext);
};

export const WsConnectionProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [messageApi, contextHolder] = message.useMessage();
    const { access_token } = useAuth();
    const [wsConnection, setWsConnection] = useState<Socket | null>(null);

    useEffect(() => {
        if (!access_token) {
            return;
        }
        setWsConnection(
            io(socketUrl, {
                auth: {
                    token: access_token,
                },
            }),
        );
    }, [access_token]);

    // use this to subscribe to some global events from BE
    useEffect(() => {
        if (!wsConnection) {
            return;
        }
        wsConnection.on('Global', (wsEventMessage: string) => {
            messageApi.info(wsEventMessage);
        });

        wsConnection.on('connect', () => {
            // messageApi.info(`Sockets connected to #${wsConnection.id}`);
        });

        wsConnection.on('disconnect', () => {
            console.log(`${wsConnection.id} disconnected`); // undefined
        });

        return () => {
            wsConnection.off('Global');
        };
    }, [messageApi, wsConnection]);

    return (
        <WsConnectionContext.Provider value={wsConnection}>
            {contextHolder}
            {children}
        </WsConnectionContext.Provider>
    );
};
