import { CredentialResponse, GoogleLogin } from '@react-oauth/google';
import { authService } from '../../../services/auth/auth.service';
import { useAuthUpdate } from '../../../context/AuthContext';
import { useCallback } from 'react';
import { message } from 'antd';
import { AuthToken } from '../../../services/auth/dto/auth-token.dto';

export const GoogleLoginButton: React.FC = () => {
    const setUserToken = useAuthUpdate();
    const [messageApi, contextHolder] = message.useMessage();

    const handleGoogleAuth = useCallback(
        (tokens: AuthToken) => {
            if (tokens) {
                messageApi.success('Login successful');
                setUserToken(tokens);
            } else {
                messageApi.error('error');
            }
        },
        [setUserToken, messageApi],
    );

    const handleSuccess = (response: CredentialResponse) => {
        if (response.credential !== undefined) {
            authService
                .loginWithGoogle(response)
                .then((res) => {
                    handleGoogleAuth(res);
                })
                .catch((e) => {
                    messageApi.error(e.message);
                });
        }
    };

    const handleError = () => {
        messageApi.error('Login Failed');
    };

    return (
        <div className="google-login-button">
            {contextHolder}
            <GoogleLogin shape={'circle'} type={'icon'} onSuccess={handleSuccess} onError={handleError} />
        </div>
    );
};
