import React from 'react';
import { Badge, Button, Col, ConfigProvider, Flex, Layout, Row, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { lightThemeConfig } from '../../../components/Layouts/themeConfigs';
import { FrequentAskedQuestions, FrequentlyAskedQuestion } from '../../../components/FrequentAskedQuestions/FrequentAskedQuestions';

const { Title, Text } = Typography;
const frequentlyAskedQuestions: FrequentlyAskedQuestion[] = [
    {
        question: 'I heard AI makes mistakes sometimes. Is PerfectSite perfect?',
        answer: 'The application of PerfectSite’s AI analysis is very good, but is not perfect. What PerfectSite does superbly is save time and effort while making your site better, with just a little help from a human friend. So, yes, do read PerfectSite’s suggestions and decide for yourself if you want to implement each one. And skip the manual reading and re-reading every time a structure or content change happens on your site until you get a clean bill of health from PerfectSite. At this point, your site will be way ahead of the game and won’t embarrass you, but…sure, do a final human pass when time allows.',
    },
    {
        question: 'If it’s a beta, why am I limited to a trial of 5 URLs?',
        answer: 'We want to give as many people as possible the chance to try PerfectSite and send us Beta feedback. AI processing is still quite intensive, and if you multiply a large number of non-paying Beta testers by unlimited processing cycles, well…you see the financial problem, right? Once we get out of Beta and into Production, the paid subscription packages will be affordable and let you perform hundreds or thousands of page scans per month.',
    },
];

export const ComingSoonBadge: React.FC = () => {
    return <Badge count="Coming soon" style={{ backgroundColor: '#122CA6', color: 'white', fontSize: '0.875rem', marginBottom: '1rem' }} />;
};

export const LandingPage: React.FC = () => {
    return (
        <>
            <div className="with-circle-bg fluid-content full-height" style={{ paddingBottom: '16rem', paddingTop: '2rem' }}>
                <Flex align={'center'} vertical gap={'middle'}>
                    <img style={{ filter: 'invert(100%)' }} width={100} height={100} alt="Door3 logo" src="/images/d3-logo.svg" />
                    <Title level={1}>A.I.-Powered Website Quality Control</Title>
                    <Title level={3} style={{ textAlign: 'center', maxWidth: '62rem' }}>
                        One test-drive will convince you that PerfectSite is your best friend and most efficient partner for ensuring your site’s quality and business
                        effectiveness. Register for our Beta and try it out today to receive a discounted membership when we launch.
                    </Title>
                    <Link to={'/registration'}>
                        <Button type="primary" shape="round" size={'large'}>
                            Register
                        </Button>
                    </Link>
                </Flex>
            </div>
            <ConfigProvider theme={lightThemeConfig}>
                <Layout>
                    <Row align={'middle'} className="fluid-content">
                        <Col span={12}>
                            <img width={237} height={237} style={{ maxWidth: '98%' }} alt="Door3 logo" src="/images/circle-grey.svg" />
                        </Col>
                        <Col span={12}>
                            <Title level={2}>PerfectSite</Title>
                            <Text type="secondary">
                                We call it that because it harnesses the power of AI to scan your website, find text errors, and seamlessly improve content. Simply input the URL
                                for quick HTML-ready corrections. And after Beta, we will be delivering an array of major features that provide 360-degree quality coverage to make
                                sure your site is a Perfect Site!
                            </Text>
                        </Col>
                    </Row>
                    <Row align={'stretch'} className="fluid-content" style={{ padding: 0, marginTop: '3rem', marginBottom: '3rem', background: 'white' }}>
                        <Col span={12}>
                            <Row align={'stretch'} style={{ height: '100%' }}>
                                <Col span={12}>
                                    <div style={{ padding: '1rem 2rem', borderRight: '1px solid #F5F5F5', height: '100%' }}>
                                        <Title level={4}>Enhanced Readability and Clarity…Today!</Title>
                                        <Text type="secondary">
                                            The PerfectSite Beta’s advanced language processing will analyze and refine the text on your web pages and quickly suggest improvements.
                                            This ensures that your intended audience experiences your brand and message the way it was meant it to be - without distracting errors.
                                            Watch your conversions and engagement level soar!
                                        </Text>
                                    </div>
                                </Col>
                                <Col span={12}>
                                    <div style={{ padding: '1rem 2rem' }}>
                                        <Title level={4}>Time and Effort Savings</Title>
                                        <Text type="secondary">
                                            Manual proofreading and editing can be time-consuming and prone to human error. Your valuable time can be focused on tasks more
                                            important than proofreading, while PerfectSite takes care of the content nuts and bolts.
                                        </Text>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={12}>
                            <img style={{ verticalAlign: 'top' }} width={'100%'} height={'100%'} alt="Door3 logo" src="/images/homepage-block1-bg.jpg" />
                        </Col>
                    </Row>
                </Layout>
            </ConfigProvider>
            <div className="with-blue-logo-bg">
                <div className="fluid-content">
                    <Title level={1} style={{ marginBottom: '5rem' }}>
                        Transforming Text for Enhanced Performance
                    </Title>
                    <Row align={'middle'} style={{ marginBottom: '5rem' }}>
                        <Col span={11}>
                            <Title style={{ marginBottom: '1rem' }} level={4}>
                                Consistent Brand Voice
                            </Title>
                            <div>
                                <ComingSoonBadge />
                            </div>
                            <Text type="secondary">
                                The AI tool ensures that the tone, style, and messaging across your website remain consistent. It adheres to predefined brand guidelines, helping to
                                establish and reinforce your brand's identity. This consistency fosters trust and familiarity with your audience, ultimately strengthening your
                                brand's online presence.
                            </Text>
                        </Col>
                        <Col span={12} offset={1}>
                            <img width={'100%'} height={'auto'} alt="App screen" src="/images/screenshot-1.jpg" />
                        </Col>
                    </Row>
                    <Row align={'middle'}>
                        <Col span={12}>
                            <img width={'100%'} height={'auto'} alt="App screen" src="/images/screenshot-2.jpg" />
                        </Col>
                        <Col span={11} offset={1}>
                            <Title style={{ marginBottom: '1rem' }} level={4}>
                                Multilingual Support
                            </Title>
                            <div>
                                <ComingSoonBadge />
                            </div>
                            <Text type="secondary">
                                Many AI tools have the capability to analyze and improve text in multiple languages. This feature is particularly valuable for businesses with a
                                global audience or those targeting diverse demographics. It enables you to maintain high-quality content in various languages, ensuring effective
                                communication and engagement with a broader range of users.
                            </Text>
                        </Col>
                    </Row>
                </div>
            </div>
            <ConfigProvider theme={lightThemeConfig}>
                <Layout>
                    <div className="fluid-content">
                        <FrequentAskedQuestions questions={frequentlyAskedQuestions}></FrequentAskedQuestions>
                        <Link to={'/faq'}>
                            <Button type="primary" shape="round" size={'large'}>
                                Read all
                            </Button>
                        </Link>
                    </div>
                </Layout>
            </ConfigProvider>
        </>
    );
};
