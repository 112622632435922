import React, { useCallback } from 'react';
import { Project } from '../../services/projects/dto/project';
import { DeleteOutlined } from '@ant-design/icons';
import { Col, Modal, Row, Typography } from 'antd';
import './ProjectMenuItem.scss';
import { projectsService } from '../../services/projects/projectsService';
import { PermissionName, usePermissions } from '../../hooks/usePermissions';

const { Text } = Typography;

export const ProjectMenuItem: React.FC<{ project: Project; onProjectDeleted?: Function }> = ({ project, onProjectDeleted }) => {
    const [modal, contextHolder] = Modal.useModal();
    const { hasPermission } = usePermissions();

    const showDeleteProjectConfirmationModal = useCallback(() => {
        const config = {
            title: 'Delete Project',
            content: `Please confirm deleting Project#${project.id} ${project.name}`,
            onOk: () => {
                if (!project.id) {
                    return;
                }
                return projectsService.deleteProjectById(project.id).then(() => {
                    if (!onProjectDeleted) {
                        return;
                    }
                    onProjectDeleted(project);
                });
            },
        };
        modal.confirm(config);
    }, [project, modal, onProjectDeleted]);

    const handleProjectDeleteIconClick = useCallback(() => {
        showDeleteProjectConfirmationModal();
        return false;
    }, [showDeleteProjectConfirmationModal]);

    return (
        <Row justify="space-between" gutter={8} align="middle">
            {contextHolder}
            <Col span={hasPermission(PermissionName.ProjectDelete) ? 21 : 24}>
                <Text ellipsis={true} style={{ verticalAlign: 'middle' }}>
                    {project.name}
                </Text>
            </Col>
            {hasPermission(PermissionName.ProjectDelete) && (
                <Col span={3}>
                    <DeleteOutlined onClick={handleProjectDeleteIconClick} />
                </Col>
            )}
        </Row>
    );
};
