import { apiService, ApiService } from '../api/apiService';
import { Analysis } from '../pages/dto/page';
import { Feedback } from '../feedbacks/dto/feedback';

export interface AnalysesQueryParamsDto {
    createdFrom?: Date | string;

    createdTo?: Date | string;
}

export interface FeedbacksQueryParamsDto {
    createdFrom?: Date | string;

    createdTo?: Date | string;
}
export class StatisticService {
    constructor(private apiService: ApiService) {}

    getFeedbacks(queryParams: FeedbacksQueryParamsDto | undefined): Promise<Feedback[]> {
        const queryString = this.apiService.prepareQueryParamsString(queryParams);
        return this.apiService.get(`feedbacks/statistic-by-period?${queryString}`);
    }

    getAnalyses(queryParams: AnalysesQueryParamsDto | undefined): Promise<Analysis[]> {
        const queryString = this.apiService.prepareQueryParamsString(queryParams);
        return this.apiService.get(`analysis/token-statistic?${queryString}`);
    }
}

export const statisticService = new StatisticService(apiService);
