import { UserSettingsDto } from '../services/settings/dto/user-settings.dto';
import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { settingsService } from '../services/settings/settingsService';
import { useAuth } from './AuthContext';

export const UserSettingsContext = React.createContext<UserSettingsDto | null>(null);
export const UserSettingsUpdateContext = React.createContext<Function>(() => {});

export const useUserSettings = () => {
    return useContext<UserSettingsDto | null>(UserSettingsContext);
};
export const useUserSettingsUpdate = () => {
    return useContext<Function>(UserSettingsUpdateContext);
};

export const UserSettingsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [userSettings, setUserSettings] = useState<UserSettingsDto | null>(null);
    const authTokens = useAuth();

    useEffect(() => {
        if (!authTokens?.access_token) {
            setUserSettings(null);
            return;
        }

        const controller = new AbortController();

        settingsService
            .getUserSettings({ signal: controller.signal })
            .then((userSettingsDto: UserSettingsDto) => {
                setUserSettings(userSettingsDto);
            })
            .catch((error) => {
                setUserSettings(null);
            });

        return () => {
            controller.abort();
        };
    }, [setUserSettings, authTokens?.access_token]);

    return (
        <UserSettingsContext.Provider value={userSettings}>
            <UserSettingsUpdateContext.Provider value={setUserSettings}>{children}</UserSettingsUpdateContext.Provider>
        </UserSettingsContext.Provider>
    );
};
