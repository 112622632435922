import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';
import { authService } from '../../../services/auth/auth.service';
import { Button, message, Typography } from 'antd';
import { AuthFormHeader } from '../AuthFormHeader/AuthFormHeader';
const { Text } = Typography;

const textStyle = { marginBottom: '2rem', justifyContent: 'center', display: 'flex', 'text-align': 'justify' };

export const ConfirmEmailPage: React.FC = () => {
    const [searchParams] = useSearchParams();
    const [emailConfirmed, setEmailConfirmed] = useState<boolean | undefined>(undefined);
    const [waitingForEmailConfirmation, setWaitingForEmailConfirmation] = useState<boolean>(false);
    const [resultsAreLoading, setResultsAreLoading] = useState(false);
    const navigate = useNavigate();
    const [messageApi, contextHolder] = message.useMessage();

    useEffect(() => {
        const token = searchParams.get('token');

        if (!token) {
            messageApi.open({ type: 'error', content: 'Token was not provided for confirming email' });
            setEmailConfirmed(false);
            return;
        }

        setWaitingForEmailConfirmation(true);
        authService
            .confirmEmail(token!)
            .then(() => {
                messageApi.open({ type: 'success', content: 'Email confirmed' });
                setEmailConfirmed(true);
            })
            .catch((ex) => {
                messageApi.open({ type: 'error', content: ex.message });
                setEmailConfirmed(false);
            })
            .finally(() => {
                setWaitingForEmailConfirmation(false);
            });
    }, [searchParams, setEmailConfirmed, messageApi, setWaitingForEmailConfirmation]);

    const handleResendConfirmLink = useCallback(() => {
        const token = searchParams.get('token');
        if (!token) {
            messageApi.open({ type: 'error', content: 'Token was not provided for resetting password' });
            return;
        }

        setResultsAreLoading(true);
        authService
            .resendConfirmEmail(token)
            .then(() => {
                messageApi.open({ type: 'success', content: 'Check your email for resetting password', duration: 4 }).then(() => {
                    navigate('/login');
                });
            })
            .catch((error) => {
                messageApi.open({ type: 'error', content: error.message });
            })
            .finally(() => {
                setResultsAreLoading(false);
            });
    }, [navigate, searchParams, messageApi]);

    return (
        <div className="auth-container">
            {contextHolder}
            <AuthFormHeader hideText={true} />
            <div className="auth-form">
                {waitingForEmailConfirmation && <Text style={textStyle}>Confirming Email. Please wait.</Text>}
                {emailConfirmed === true && (
                    <>
                        <Text style={textStyle}>Email confirmed</Text>
                        <Link to={'/login'}>
                            <Button block shape="round" type="primary">
                                Go back to login
                            </Button>
                        </Link>
                    </>
                )}
                {emailConfirmed === false && (
                    <>
                        <Text style={textStyle}>Email not confirmed</Text>
                        <Text style={textStyle}>We regret to inform you that the confirmation link for your registration has expired.</Text>

                        <Text style={textStyle}>
                            To complete your registration process, we kindly ask you to visit our website once more and re-register. Upon successful registration, you will receive
                            a new confirmation email. We apologize for any inconvenience this may have caused and appreciate your understanding.
                        </Text>

                        <Text style={textStyle}>
                            If you have any questions or need further assistance, please do not hesitate to reach out to our support team at{' '}
                            <a href="mailto: support.perfectsite@door3.com" style={{ display: 'contents' }}>
                                support
                            </a>
                        </Text>
                        <Button block shape="round" type="primary" onClick={handleResendConfirmLink} loading={resultsAreLoading}>
                            Resend new confirmation
                        </Button>
                    </>
                )}
            </div>
        </div>
    );
};
