import React from 'react';
import { ConfigProvider, Layout } from 'antd';
import { darkThemeConfig } from '../themeConfigs';
import { UnAuthHeader } from '../UnAuthHeader/UnAuthHeader';
import { Outlet } from 'react-router-dom';
import { AppFooter } from '../AppFooter/AppFooter';

const { Content } = Layout;
export const StaticContentLayout: React.FC = () => {
    return (
        <ConfigProvider theme={darkThemeConfig}>
            <Layout style={{ minHeight: '100vh' }}>
                <UnAuthHeader />
                <Content>
                    <Outlet />
                </Content>
                <AppFooter />
            </Layout>
        </ConfigProvider>
    );
};
