import React, { ReactNode, useContext, useState } from 'react';

export const ActiveProjectContext = React.createContext<number | null>(null);
export const ActiveProjectUpdateContext = React.createContext<Function>(() => {});

export const useActiveProjectId = () => {
    return useContext<number | null>(ActiveProjectContext);
};

export const useActiveProjectIdUpdate = () => {
    return useContext<Function>(ActiveProjectUpdateContext);
};

export const ActiveProjectProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [projectId, setProjectId] = useState<number | null>(null);
    return (
        <ActiveProjectContext.Provider value={projectId}>
            <ActiveProjectUpdateContext.Provider value={setProjectId}>{children}</ActiveProjectUpdateContext.Provider>
        </ActiveProjectContext.Provider>
    );
};
