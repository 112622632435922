import React from 'react';
import { ConfigProvider, Layout } from 'antd';
import { darkThemeConfig, headerHeight } from '../themeConfigs';
import { UnAuthHeader } from '../UnAuthHeader/UnAuthHeader';
import { Outlet } from 'react-router-dom';

const { Content } = Layout;
export const UnAuthLayout: React.FC = () => {
    return (
        <ConfigProvider theme={darkThemeConfig}>
            <Layout style={{ minHeight: '100vh' }} className="with-circle-bg">
                <UnAuthHeader />
                <Content style={{ minHeight: `calc(100vh - ${headerHeight})`, display: 'flex', flexDirection: 'column' }}>
                    <Outlet />
                </Content>
            </Layout>
        </ConfigProvider>
    );
};
