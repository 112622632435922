import { Analysis, AnalysisType } from "../../services/pages/dto/page";
import { LinkChecker } from "./LinkChecker/LinkChecker";
import { QualityAnalysis } from "./QualityAnalysis/QualityAnalysis";

export const renderAnalysisResult = (analysis: Analysis, pageText: string, style: string, updateAnalysis: Function) => {
    switch (analysis.type) {
        case AnalysisType.LinkChecker:
            return <LinkChecker analysis={analysis} />
        case AnalysisType.Quality:
            return <QualityAnalysis analysis={analysis} pageText={pageText} style={style} updateAnalysis={updateAnalysis} />
        default:
            return <div className="formatted-text scrollable-content-page-details">{analysis.result}</div>;
    }
};