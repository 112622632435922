import React, { Key, useCallback, useEffect, useState } from 'react';
import { Tree } from 'antd';
import convertSitemapIntoDataNodes from './utils/conver-sitemap-into-tree';
import { Page } from '../../services/pages/dto/page';
import { TreeDataNode } from './models/tree-data-node';

export const SiteMapTree: React.FC<{ sitemap: Page[]; onTreeItemSelect: Function }> = ({ sitemap, onTreeItemSelect }) => {
    const [sitemapPages, setSitemapPages] = useState<TreeDataNode[]>([]);
    const [selectedKey, setSelectedKey] = useState<string | number>('');

    useEffect(() => {
        setSitemapPages(convertSitemapIntoDataNodes(sitemap));
    }, [sitemap]);

    const handleSelection = useCallback(
        (
            selectedKeys: Key[],
            info: {
                event: 'select';
                selected: boolean;
                node: TreeDataNode;
                selectedNodes: TreeDataNode[];
                nativeEvent: MouseEvent;
            },
        ) => {
            if (info.selectedNodes.length && info.node.item) {
                onTreeItemSelect(info.node.item);
                setSelectedKey(info.node.key);
                return;
            }
            onTreeItemSelect(undefined);
            setSelectedKey('');
        },
        [onTreeItemSelect],
    );

    useEffect(() => {
        if (sitemapPages?.length === 0 || selectedKey) {
            return;
        }

        onTreeItemSelect(sitemapPages[0].item);
        setSelectedKey(sitemapPages[0].key);
    }, [sitemapPages, onTreeItemSelect, selectedKey]);

    return (
        <div className="SiteMapTree">
            {sitemapPages.length > 0 && <Tree showLine defaultExpandAll={true} selectedKeys={[selectedKey]} treeData={sitemapPages} onSelect={handleSelection} />}
        </div>
    );
};
