import { apiService, ApiService } from '../api/apiService';
import { Feedback } from './dto/feedback';

export class FeedbacksService {
    constructor(private apiService: ApiService) {}

    getAllFeedbacks(): Promise<Feedback[]> {
        return this.apiService.get('feedbacks');
    }

    sendFeedback(feedback: Feedback): Promise<Feedback> {
        return this.apiService.post(`feedbacks`, { body: feedback });
    }
}

export const feedbacksService = new FeedbacksService(apiService);
