export enum Color {
    Pink = 'pink',
    Red = 'red',
    Yellow = 'yellow',
    Orange = 'orange',
    Cyan = 'cyan',
    Green = 'green',
    Blue = 'blue',
    Purple = 'purple',
    Geekblue = 'geekblue',
    Magenta = 'magenta',
    Volcano = 'volcano',
    Gold = 'gold',
    Lime = 'lime',
    White = 'white',
}
