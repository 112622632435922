import React from 'react';
import { Badge, Space, Typography, theme } from 'antd';
import { Link } from 'react-router-dom';
import { primaryColor } from '../themeConfigs';
const { Title } = Typography;

const { useToken } = theme;

export const SiteLogo: React.FC = () => {
    const { token } = useToken();
    const isDarkTheme = token.colorText === "#ffffff";

    return (
        <div style={{ height: '2rem', display: 'flex' }}>
            <Badge count={'Beta'} offset={[32, 8]} color={primaryColor} size="small" style={{ color: 'white' }}>
                <Space align="center">
                    <Link to="/" style={{ height: '2rem', width: 'auto', display: 'flex' }}>
                        <img style={{ filter: isDarkTheme ? 'invert(100%)' : 'none' }} width={32} height={32} alt="Door3 logo" src="/images/d3-logo.svg" />
                        <Title level={3} style={{ fontWeight: 600, margin: '0 0 0 0.875rem' }}>
                            PerfectSite.ai
                        </Title>
                    </Link>
                </Space>
            </Badge>
        </div>
    );
};
