import React, { useCallback, useEffect, useState } from 'react';
import { Alert, Col, Layout, message, Row } from 'antd';
import './HomePage.scss';
import { ProjectDetails } from '../../components/ProjectDetails/ProjectDetails';
import { Project } from '../../services/projects/dto/project';
import { ProjectsMenu } from '../../components/ProjectsMenu/ProjectsMenu';
import { projectsService } from '../../services/projects/projectsService';
import { useWsConnection } from '../../context/WsConnectionContext';
import { AddNewProjectForm } from '../../components/AddNewProjectForm/AddNewProjectForm';
import { useUserSettings } from '../../context/UserSettingsContext';
import { PermissionName, usePermissions } from '../../hooks/usePermissions';
import { useActiveProjectIdUpdate } from '../../context/ActiveProjectContext';
import { useActiveAnalysisIdUpdate } from '../../context/ActiveAnalysisContext';

const { Sider } = Layout;

export const HomePage: React.FC = () => {
    const [projectId, setProjectId] = useState<null | number>(null);
    const [projectsLoaded, setProjectsLoaded] = useState<boolean>(false);
    const [projects, setProjects] = useState<Project[]>([]);
    const wsConnection = useWsConnection();
    const [messageApi, contextHolder] = message.useMessage();
    const { hasPermission } = usePermissions();
    const userSettings = useUserSettings();
    const setActiveProjectId = useActiveProjectIdUpdate();
    const setActiveAnalysisId = useActiveAnalysisIdUpdate();

    const updateProjectInProjectsList = useCallback(
        (project: Project, allProjects: Project[]) => {
            const projectAlreadyExist = allProjects.findIndex(({ id }) => project.id === id) > -1;
            let updatedProject = [];
            if (projectAlreadyExist) {
                updatedProject = allProjects.map((oldProject) => {
                    if (oldProject.id === project.id) {
                        return project;
                    }
                    return oldProject;
                });
            } else {
                updatedProject = [project, ...allProjects];
            }
            setProjects(updatedProject);
        },
        [setProjects],
    );

    const loadAllProjects = useCallback(() => {
        setProjectsLoaded(false);
        projectsService.getAllProjects().then((projects) => {
            setProjects(projects);
            setProjectsLoaded(true);
        });
    }, []);

    const handleProjectDeleted = useCallback(
        (projectId: null | number) => {
            setProjectId(null);
            loadAllProjects();
        },
        [loadAllProjects],
    );

    const handleActiveProjectIdChange = useCallback(
        (projectId: null | number) => {
            setProjectId(projectId);
            loadAllProjects();
        },
        [setProjectId, loadAllProjects],
    );

    const handleNewProjectAdded = useCallback(
        (project: Project) => {
            updateProjectInProjectsList(project, projects);
            setProjectId(project.id);
        },
        [updateProjectInProjectsList, setProjectId, projects],
    );

    useEffect(() => {
        if (!wsConnection) {
            return;
        }

        const projectCreatedHandler = (event: { message: string; project: Project }) => {
            messageApi.success(event.message);
            updateProjectInProjectsList(event.project, projects);
        };
        wsConnection.on('ProjectCreated', projectCreatedHandler);

        const projectUpdatedHandler = (event: { message: string; project: Project }) => {
            // messageApi.success(event.message);
            updateProjectInProjectsList(event.project, projects);
        };
        wsConnection.on('ProjectUpdated', projectUpdatedHandler);

        return () => {
            wsConnection.off('ProjectCreated', projectCreatedHandler);
            wsConnection.off('ProjectUpdated', projectUpdatedHandler);
        };
    }, [projects, wsConnection, messageApi, updateProjectInProjectsList]);

    useEffect(() => {
        loadAllProjects();
    }, [loadAllProjects]);

    useEffect(() => {
        setActiveProjectId(projectId);
        if (!projectId) {
            setActiveAnalysisId(null);
        }
        return () => {
            setActiveProjectId(null);
            setActiveAnalysisId(null);
        };
    }, [projectId, setActiveProjectId, setActiveAnalysisId]);

    return (
        <Layout className="HomePageWrapper" hasSider={true}>
            {contextHolder}
            <Sider theme="light" width={240} className="ProjectsMenu">
                <ProjectsMenu projects={projects} activeProjectId={projectId} onActiveProjectIdChanged={handleActiveProjectIdChange} onProjectDeleted={handleProjectDeleted} />
            </Sider>
            <Layout className="HomePageContent">
                {hasPermission(PermissionName.ProjectCreate) && projectsLoaded && !projectId && (
                    <>
                        {projects.length >= (userSettings?.limitOfProjects || 1) && (
                            <Alert
                                message={`You used your limit of ${userSettings?.limitOfProjects || 1} project(s). Please upgrade your plan to be able to add more projects.`}
                                type="warning"
                            />
                        )}
                        {projects.length < (userSettings?.limitOfProjects || 1) && (
                            <Row align="middle" justify="center">
                                <Col span={14}>
                                    <AddNewProjectForm onProjectAdded={handleNewProjectAdded} />
                                </Col>
                            </Row>
                        )}
                    </>
                )}
                {projectsLoaded && projectId && <ProjectDetails id={projectId}></ProjectDetails>}
            </Layout>
        </Layout>
    );
};
