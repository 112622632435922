import { Color } from './color.enum';

export enum HttpResponseType {
    Informational = 1, // (100 – 199)
    Successful = 2, // (200 – 299)
    Redirection = 3, // (300 – 399)
    ClientError = 4, // (400 – 499)
    ServerError = 5, // (500 – 599)
    UnknownResponseType = 6,
}

export const getHttpResponseType = (statusCode: number): HttpResponseType => {
    switch (Math.floor(statusCode / 100)) {
        case 1:
            return HttpResponseType.Informational;
        case 2:
            return HttpResponseType.Successful;
        case 3:
            return HttpResponseType.Redirection;
        case 4:
            return HttpResponseType.ClientError;
        case 5:
            return HttpResponseType.ServerError;
        default:
            return HttpResponseType.UnknownResponseType;
    }
};

export const HttpResponseTypeColors = {
    [HttpResponseType.Informational]: Color.Green,
    [HttpResponseType.Successful]: Color.Green,
    [HttpResponseType.Redirection]: Color.Yellow,
    [HttpResponseType.ClientError]: Color.Red,
    [HttpResponseType.ServerError]: Color.Red,
    [HttpResponseType.UnknownResponseType]: Color.White,
};
