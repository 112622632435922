import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { GlobalAppSettingsDto } from '../services/settings/dto/global-app-settings.dto';
import { settingsService } from '../services/settings/settingsService';

export const AppSettingsContext = React.createContext<GlobalAppSettingsDto | null>(null);
export const AppSettingsUpdateContext = React.createContext<Function>(() => {});

export const useAppSettings = () => {
    return useContext<GlobalAppSettingsDto | null>(AppSettingsContext);
};
export const useAppSettingsUpdate = () => {
    return useContext<Function>(AppSettingsUpdateContext);
};

export const AppSettingsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [appSettings, setAppSettings] = useState<GlobalAppSettingsDto | null>(null);

    useEffect(() => {
        const controller = new AbortController();

        settingsService
            .getGlobalAppSettings({ signal: controller.signal })
            .then((appSettings: GlobalAppSettingsDto) => {
                setAppSettings(appSettings);
            })
            .catch((error) => {
                setAppSettings(null);
            });

        return () => {
            controller.abort();
        };
    }, [setAppSettings]);

    return (
        <AppSettingsContext.Provider value={appSettings}>
            <AppSettingsUpdateContext.Provider value={setAppSettings}>{children}</AppSettingsUpdateContext.Provider>
        </AppSettingsContext.Provider>
    );
};
