import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Alert, Button, Checkbox, Col, Form, Input, Row, Space, Switch, Tooltip } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { SearchOutlined } from '@ant-design/icons';
import { EditPromptConfigurationButton } from '../EditPromptConfigurationButton/EditPromptConfigurationButton';
import { PromptConfigurationDto, PromptConfigurationLevel } from '../../services/settings/dto/prompt-configuration.dto';
import { analyzerService } from '../../services/analyzer/analyzerService';
import { Rule } from 'antd/es/form';
import { useLookups } from '../../context/LookupContext';
import { CheckboxOptionType, CheckboxValueType } from 'antd/es/checkbox/Group';
import { AnalysisType } from '../../services/pages/dto/page';
import { useAppSettings } from '../../context/AppSettingsContext';
import { useUserSettings } from '../../context/UserSettingsContext';
import { PermissionName, usePermissions } from '../../hooks/usePermissions';
import { usersService } from '../../services/users/users.service';
import { urlValidationRules } from '../../pages/TrialPage/TrialPage';

function interpolateTemplateString(template: string, params: any) {
    const names = Object.keys(params);
    const values = Object.values(params);
    try {
        // eslint-disable-next-line no-new-func
        return new Function(...names, `return \`${template}\`;`)(...values);
    } catch (error) {
        // @ts-ignore
        return `Can't interpolate string "${template}", because ${error.message}.`;
    }
}

interface FormValues {
    url: string;
    siteObjectiveContext: string;
    companyContext: string;
    includeSubURLs: boolean;
    name: string;
    analysisTypes: string[];
}

const layout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 20 },
};

export const contextMaxLength = 400;

const formValidationRules: { [key: string]: Rule[] } = {
    name: [
        {
            required: true,
            message: 'Please enter project name',
        },
    ],
    url: urlValidationRules,
    context: [
        {
            type: 'string',
            max: contextMaxLength,
        },
    ],
};

type PromptByAnalysisType = {
    [key in AnalysisType]?: string;
};

const defaultPromptByAnalysisType: PromptByAnalysisType = {
    [AnalysisType.SEO]: 'seo',
    [AnalysisType.Marketing]: 'mark',
    [AnalysisType.Quality]: 'quality',
};

const errorAlertMessage =
    'Projected analyses count is greater than limit of analyses to run. You will get only limited analyses count in random order. Please deselect scan sub urls or some analysis types to fix the issue.';

export const AddNewProjectForm: React.FC<{ onProjectAdded: Function }> = ({ onProjectAdded }) => {
    const [form] = Form.useForm();
    const includeSubURLsValue = Form.useWatch('includeSubURLs', form);
    const selectedAnalysisTypes = Form.useWatch('analysisTypes', form);
    const [promptConfig, setPromptConfig] = useState<PromptConfigurationDto | null>(null);
    const [resultsAreLoading, setResultsAreLoading] = useState(false);
    const lookups = useLookups();
    const [actionName, setActionName] = useState<string>('Scan site');
    const [analysisTypes, setAnalysisTypes] = useState<CheckboxOptionType[]>([]);
    const [promptByAnalysisTypes, setPromptByAnalysisTypes] = useState<PromptByAnalysisType>(defaultPromptByAnalysisType);
    const appSettings = useAppSettings();
    const userSettings = useUserSettings();
    const { hasPermission } = usePermissions();
    const [limitOfAnalysesToRun, setLimitOfAnalysesToRun] = useState(0);

    const limitOfPagesToScan = userSettings?.limitOfPagesToScan || 100;

    const projectedAnalysisCount = useMemo(() => {
        const selectedAnalysisTypeCount = Array.isArray(selectedAnalysisTypes) ? selectedAnalysisTypes.length : 0;
        const projectedPagesCount = includeSubURLsValue ? limitOfPagesToScan : 1;
        return selectedAnalysisTypeCount * projectedPagesCount;
    }, [selectedAnalysisTypes, includeSubURLsValue, limitOfPagesToScan]);

    useEffect(() => {
        usersService.getAvailableTokens().then((tokens) => {
            setLimitOfAnalysesToRun(tokens);
        });
    }, [setLimitOfAnalysesToRun]);

    useEffect(() => {
        setAnalysisTypes(
            (lookups?.analysisTypes || []).map(
                ({ id, name }) =>
                    ({
                        value: id,
                        label: (
                            <Tooltip placement="top" title={promptByAnalysisTypes[id as AnalysisType]}>
                                {name}
                            </Tooltip>
                        ),
                    } as CheckboxOptionType),
            ),
        );
    }, [setAnalysisTypes, lookups?.analysisTypes, promptByAnalysisTypes]);

    const recalculatePromptByAnalysisTypes = useCallback(() => {
        if (!promptConfig) {
            return;
        }

        const { companyPromptTemplate, sitePromptTemplate, pagePromptTemplate, combinedPromptTemplate, analysisPrompts } = promptConfig;

        const companyContext = form.getFieldValue('companyContext');
        const companyPromptPart = companyContext ? interpolateTemplateString(companyPromptTemplate, { companyContext }) : '';

        const siteContext = form.getFieldValue('siteObjectiveContext');
        const sitePromptPart = siteContext ? interpolateTemplateString(sitePromptTemplate, { siteContext }) : '';

        const pageContext = '';
        const pagePromptPart = pageContext ? interpolateTemplateString(pagePromptTemplate, { pageContext }) : '';

        const updatedPromptByAnalysisTypes = { ...defaultPromptByAnalysisType };
        analysisPrompts.forEach(({ analysisType, promptTemplate, inputType }) => {
            const analysisQuestion = promptTemplate;
            const analysisInput = `"Content to analyse (Scanned page ${inputType})"`; // page text | page html
            updatedPromptByAnalysisTypes[analysisType] = interpolateTemplateString(combinedPromptTemplate, {
                companyPromptPart,
                sitePromptPart,
                pagePromptPart,
                analysisQuestion,
                analysisInput,
            });
        });

        setPromptByAnalysisTypes(updatedPromptByAnalysisTypes);
    }, [promptConfig, form, setPromptByAnalysisTypes]);

    useEffect(() => {
        recalculatePromptByAnalysisTypes();
    }, [promptConfig, recalculatePromptByAnalysisTypes]);

    useEffect(() => {
        form.resetFields();
    }, [form]);

    useEffect(() => {
        setPromptConfig(
            appSettings
                ? {
                      ...appSettings.promptConfiguration,
                      level: PromptConfigurationLevel.Application,
                  }
                : null,
        );
    }, [setPromptConfig, appSettings]);

    const handleFormChange = useCallback(() => {
        recalculatePromptByAnalysisTypes();
    }, [recalculatePromptByAnalysisTypes]);

    const handleScanUrlFormSubmit = useCallback(
        (values: FormValues) => {
            if (!values.url) {
                // setProjectId(null);
                return;
            }

            setResultsAreLoading(true);

            analyzerService
                .analyseSiteByUrl({
                    ...values,
                    promptConfiguration: promptConfig,
                })
                .then((createdProject) => {
                    onProjectAdded(createdProject);
                    form.resetFields();
                })
                .finally(() => {
                    setResultsAreLoading(false);
                });
        },
        [form, promptConfig, onProjectAdded],
    );

    const handlePromptConfigUpdate = useCallback(
        (updatedPromptConfig: PromptConfigurationDto) => {
            setPromptConfig({
                ...updatedPromptConfig,
                level: PromptConfigurationLevel.Project,
            });
        },
        [setPromptConfig],
    );

    const onCheckAnalysisType = useCallback(
        (checkedValues: CheckboxValueType[]) => {
            checkedValues.length > 0 ? setActionName('Scan site and analyse') : setActionName('Scan site');
        },
        [setActionName],
    );

    return (
        <Form {...layout} form={form} name="control-hooks" onChange={handleFormChange} onFinish={handleScanUrlFormSubmit} size="large">
            <Form.Item name="name" label="Project name" rules={formValidationRules.name}>
                <Input placeholder="Please input project name" allowClear={true} />
            </Form.Item>
            <Form.Item name="url" label="URL:" rules={formValidationRules.url}>
                <Input placeholder="Please input site url to analise" allowClear={true} />
            </Form.Item>
            <Form.Item
                name="includeSubURLs"
                label="Include sub urls"
                valuePropName="checked"
                tooltip={includeSubURLsValue ? 'Make sure you want to analyze the entire site with all sublinks when selecting this option' : ''}
            >
                <Switch />
            </Form.Item>
            {hasPermission(PermissionName.ContextEdit) && (
                <Form.Item name="companyContext" label="Company" rules={formValidationRules.context}>
                    <TextArea showCount maxLength={contextMaxLength} autoSize={{ minRows: 2, maxRows: 4 }} placeholder="Please describe the company that this site serves" />
                </Form.Item>
            )}
            {hasPermission(PermissionName.ContextEdit) && (
                <Form.Item name="siteObjectiveContext" label="Site Objective" rules={formValidationRules.context}>
                    <TextArea
                        showCount
                        maxLength={contextMaxLength}
                        autoSize={{ minRows: 2, maxRows: 4 }}
                        placeholder="Please describe the key objective that the site is intended to achieve for the company."
                    />
                </Form.Item>
            )}
            <Form.Item label="Analyses types" style={{ marginBottom: 0 }}>
                {includeSubURLsValue && (
                    <Form.Item style={{ display: 'inline-block' }}>
                        <Checkbox disabled={true} defaultChecked></Checkbox>
                        <span className="create-sitemap">Create sitemap</span>
                    </Form.Item>
                )}
                <Form.Item name="analysisTypes" style={{ display: 'inline-block' }}>
                    <Checkbox.Group options={analysisTypes} onChange={onCheckAnalysisType} />
                </Form.Item>
            </Form.Item>
            <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 5 }}>
                <Space size={8}>
                    <Button type="primary" htmlType="submit" icon={<SearchOutlined />} loading={resultsAreLoading}>
                        {actionName}
                    </Button>
                    {promptConfig && hasPermission(PermissionName.GlobalPromptConfigEdit) && (
                        <EditPromptConfigurationButton
                            editPromptConfigButtonText="Update Prompts for the Project"
                            initialPromptConfig={promptConfig}
                            onPromptConfigUpdated={handlePromptConfigUpdate}
                        />
                    )}
                </Space>
            </Form.Item>
            <Row>
                <Col offset={5} span={19}>
                    <Alert
                        message={
                            <>
                                <Row>
                                    <Col span={10}>Limit of pages to scan:</Col>
                                    <Col span={4}>{limitOfPagesToScan}</Col>
                                </Row>
                                <Row>
                                    <Col span={10}>Limit of analyses to run:</Col>
                                    <Col span={4}>{limitOfAnalysesToRun}</Col>
                                </Row>
                                <Row>
                                    <Col span={10}>Projected analyses count:</Col>
                                    <Col span={4}>{projectedAnalysisCount}</Col>
                                </Row>
                            </>
                        }
                        type="warning"
                    />
                    {projectedAnalysisCount > limitOfAnalysesToRun && <Alert style={{ marginTop: '0.5rem' }} message={errorAlertMessage} type={'error'} />}
                </Col>
            </Row>
        </Form>
    );
};
