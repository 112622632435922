import { Button, Pagination, PaginationProps, Tooltip, Typography, message, Row, Col, Table, Avatar } from 'antd';
import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import reactStringReplace from 'react-string-replace';
import { Analysis } from '../../../services/pages/dto/page';
import { CopyOutlined, CheckOutlined, DislikeOutlined, LikeOutlined } from '@ant-design/icons';
import './QualityAnalysis.scss';
import { analyzerService } from '../../../services/analyzer/analyzerService';
import { FeedbackType } from '../../../services/analyzer/dto/analysis-feedback.dto';

const { Text } = Typography;
const fixClass = 'fix';
const selectedFixClass = 'selected-fix';
const unhelpfulFixClass = 'unhelpful';
const helpfulFixClass = 'helpful';

interface QualityFix {
    mistake: string;
    fix: string;
    full_sentence: string;
}

const CopyTextButton: React.FC<{ text: string }> = ({ text }) => {
    const [messageApi, contextHolder] = message.useMessage();
    const [copied, setCopied] = useState<boolean>(false);

    const addTextToClipboard = useCallback(() => {
        setCopied(true);
        navigator.clipboard.writeText(text);
        messageApi.open({
            type: 'success',
            content: 'Text was successfully copied to clipboard',
        });
        setTimeout(() => {
            setCopied(false);
        }, 1000);
    }, [messageApi, text]);

    return (
        <>
            {contextHolder}
            <Button onClick={addTextToClipboard} type="primary" size="small" shape="circle" icon={copied ? <CheckOutlined /> : <CopyOutlined />} />
        </>
    );
};

const fixesColumns = [
    {
        title: 'Finding',
        dataIndex: 'mistake',
        key: 'mistake',
    },
    {
        title: 'Suggestion',
        dataIndex: 'fix',
        key: 'fix',
    },
    {
        title: 'Sentence',
        dataIndex: 'full_sentence',
        key: 'full_sentence',
    },
];
export const NotFormattedResult: React.FC<{ result: string; style: string; fixes?: QualityFix[] }> = ({ result, style, fixes = [] }) => {
    let formattedResult = result;
    if (fixes.length > 0) {
        const indexOfFirstOpenCurlyBracket = result.indexOf('{');
        const indexOfLastCloseCurlyBracket = result.lastIndexOf('}');
        if (indexOfFirstOpenCurlyBracket && indexOfLastCloseCurlyBracket && indexOfFirstOpenCurlyBracket < indexOfLastCloseCurlyBracket) {
            const textBeforeCurlyBracket = result.slice(0, indexOfFirstOpenCurlyBracket);
            const textAfterCurlyBracket = result.slice(indexOfLastCloseCurlyBracket + 1);
            formattedResult = textBeforeCurlyBracket + textAfterCurlyBracket;
        }
    }
    return (
        <div className="analysis-status-details">
            <p>
                <b>Raw response type. Not formatted result:</b>
            </p>
            <div className={`formatted-text ${style}`}>{formattedResult}</div>
            {fixes.length > 0 && <Table bordered pagination={false} size={'small'} dataSource={fixes} columns={fixesColumns} />}
        </div>
    );
};

export const QualityAnalysis: React.FC<{ analysis: Analysis; pageText: string; style: string; updateAnalysis: Function }> = ({ analysis, pageText, style, updateAnalysis }) => {
    const [currentFix, setCurrentFix] = useState(0);
    const ref = useRef<HTMLSpanElement[]>([]);
    const analysisId = analysis.id;
    const pushRef = (el: HTMLSpanElement) => ref.current.push(el);
    const [messageApi, contextHolder] = message.useMessage();
    const [sendingHelpfulFeedback, setSendingHelpfulFeedback] = useState(false);

    const percentAnalyzed = useMemo(() => {
        const { analyzedWordsCount = 1, pageWordsCount = 1 } = analysis;
        if (analyzedWordsCount < pageWordsCount) {
            return Math.round((analyzedWordsCount * 100) / pageWordsCount);
        }
        return 100;
    }, [analysis]);

    const [sendingUnHelpfulFeedback, setSendingUnHelpfulFeedback] = useState(false);

    const updateAnalysisFeedbacks = useCallback(
        (qf: QualityFix, analysisId: number, feedbackType: FeedbackType) => {
            const { fix, mistake, full_sentence } = qf;
            let hasFeedback = false;
            let updatedAnalysis = analysis;

            updatedAnalysis.analysisFeedbacks.map((x) => {
                if (x.fix === fix && x.mistake === mistake && x.fullSentence === full_sentence) {
                    hasFeedback = true;
                    x.type = feedbackType;
                }

                return x;
            });

            if (!hasFeedback) {
                updatedAnalysis.analysisFeedbacks = [...analysis.analysisFeedbacks, { fix, mistake, type: feedbackType, analysisId, fullSentence: full_sentence }];
            }

            updateAnalysis([updatedAnalysis]);
        },
        [updateAnalysis, analysis],
    );

    const getFixHighlight = useCallback((className: string) => {
        const isUnhelpful = className.includes(unhelpfulFixClass);
        const isHelpful = className.includes(helpfulFixClass);

        if (isUnhelpful || isHelpful) {
            return isUnhelpful ? unhelpfulFixClass : helpfulFixClass;
        }

        return '';
    }, [])

    const setLoadingFeedback = useCallback((feedbackType: FeedbackType) => {
        if (feedbackType === FeedbackType.Helpful) {
            setSendingHelpfulFeedback(true);
        } else {
            setSendingUnHelpfulFeedback(true);
        }
    }, []);

    const resetLoadingFeedback = useCallback(() => {
        setSendingHelpfulFeedback(false);
        setSendingUnHelpfulFeedback(false);
    }, []);

    const addFeedback = useCallback(
        (grammarFinding: QualityFix, feedbackType: FeedbackType) => {
            const { fix, mistake, full_sentence } = grammarFinding;
            setLoadingFeedback(feedbackType);

            analyzerService
                .addFeedback({ fix, mistake, fullSentence: full_sentence, analysisId, type: feedbackType })
                .then((res) => {
                    updateAnalysisFeedbacks(grammarFinding, analysisId, feedbackType);
                })
                .catch((ex) => {
                    messageApi.error(ex.message);
                })
                .finally(() => resetLoadingFeedback());
        },
        [messageApi, resetLoadingFeedback, setLoadingFeedback, analysisId, updateAnalysisFeedbacks],
    );

    const resetSelections = useCallback(() => {
        ref.current.forEach((x) => {
            if (x) {
                x.className = `${fixClass} ${getFixHighlight(x.className)}`;
            }
        });

        if (ref.current && ref.current[currentFix - 1] && currentFix > 0) {
            ref.current[currentFix - 1].className = `${selectedFixClass} ${getFixHighlight(ref.current[currentFix - 1].className)}`;
        }
    }, [ref, currentFix, getFixHighlight]);

    useEffect(() => resetSelections());

    const handlePaginationChange: PaginationProps['onChange'] = useCallback(
        (pageNumber: number) => {
            setCurrentFix(pageNumber);
            resetSelections();
            const pageIndex = pageNumber - 1;
            const highlightedElement = ref.current[pageIndex];
            if (!highlightedElement) {
                return;
            }
            highlightedElement.scrollIntoView();
            highlightedElement.click();
            highlightedElement.className = `${selectedFixClass} ${getFixHighlight(highlightedElement.className)}`;
        },
        [setCurrentFix, ref, resetSelections, getFixHighlight],
    );

    const openTooltipChange = (open: boolean) => {
        if (!open) resetSelections();
    };

    const hasFeedback = (grammarFinding: QualityFix, feedbackType: FeedbackType) => {
        const { fix, mistake, full_sentence } = grammarFinding;

        return analysis.analysisFeedbacks.some((x) => x.mistake === mistake && x.fix === fix && x.fullSentence === full_sentence && x.type === feedbackType);
    };

    const renderPagination: PaginationProps['itemRender'] = (_, type, originalElement) => {
        if (type === 'prev') {
            return (
                <Button type="default" size="small">
                    Previous
                </Button>
            );
        }
        if (type === 'next') {
            return (
                <Button type="primary" size="small">
                    Next
                </Button>
            );
        }
        return originalElement;
    };

    try {
        let analysisResult: string = analysis.result;
        // remove some text before json
        const indexOfFirstOpenCurlyBracket = analysisResult.indexOf('{');
        if (indexOfFirstOpenCurlyBracket > 0) {
            analysisResult = analysisResult.slice(indexOfFirstOpenCurlyBracket);
        }
        // remove some text after json
        const indexOfLastCloseCurlyBracket = analysisResult.lastIndexOf('}');
        if (indexOfLastCloseCurlyBracket > 0) {
            analysisResult = analysisResult.slice(0, indexOfLastCloseCurlyBracket + 1);
        }

        const formattedResults: { results: QualityFix[] } = JSON.parse(analysisResult);
        const fixes = formattedResults.results.filter(({ mistake, fix }) => mistake !== fix);

        if (fixes.length === 0) {
            return (
                <div className={'analysis-status-details'}>
                    The text provided does not contain any grammatical errors or issues with content. Therefore, no corrections are needed.
                </div>
            );
        }

        let analysisResultHtml: ReactNode[] | string = analysis.contentToAnalyze || pageText || '';
        let totalFixes = 0;
        fixes.forEach((grammarFinding) => {
            let replacementsCount = 0;
            analysisResultHtml = reactStringReplace(analysisResultHtml, grammarFinding.mistake, (match, i, offset) => {
                if (replacementsCount >= 2) {
                    return match;
                }

                if (grammarFinding.mistake !== match) {
                    return match;
                }

                const hasHelpfulFeedback = hasFeedback(grammarFinding, FeedbackType.Helpful);
                const hasUnhelpfulFeedback = hasFeedback(grammarFinding, FeedbackType.Unhelpful);

                const highlightClass = hasUnhelpfulFeedback ? unhelpfulFixClass :
                    hasHelpfulFeedback ? helpfulFixClass : '';

                let { full_sentence, fix, mistake } = grammarFinding;
                full_sentence = full_sentence || '';
                const sentenceStartedAtIndex = analysisResultHtml.indexOf(full_sentence);
                const sentenceLength = full_sentence.length;
                const mistakeLength = mistake.length;
                const mistakeIsOutOfSentence = offset < sentenceStartedAtIndex - sentenceLength || offset > sentenceStartedAtIndex + sentenceLength;

                if (sentenceStartedAtIndex > -1 && sentenceLength >= mistakeLength && mistakeIsOutOfSentence) {
                    return match;
                }

                totalFixes++;
                replacementsCount++;
                return (
                    <Tooltip
                        key={totalFixes}
                        destroyTooltipOnHide={true}
                        placement="right"
                        afterOpenChange={openTooltipChange}
                        trigger={['hover', 'click']}
                        title={
                            <span>
                                <span className="tooltip-title">Suggested improvement:</span>
                                <div className="tooltip-body">{fix}</div>
                                <div className="tooltip-actions">
                                    <div>
                                        <div className="action">
                                            <CopyTextButton text={fix} />
                                        </div>
                                    </div>
                                    <div>
                                        <div className="action">
                                            <Button
                                                className={`ant-btn-icon-green ${hasHelpfulFeedback ? 'muted' : ''}`}
                                                size="small"
                                                onClick={() => addFeedback(grammarFinding, FeedbackType.Helpful)}
                                                shape="circle"
                                                icon={<LikeOutlined />}
                                                loading={sendingHelpfulFeedback}
                                                disabled={hasHelpfulFeedback}
                                            />
                                            <Button
                                                className={`ant-btn-icon-red ${hasUnhelpfulFeedback ? 'muted' : ''}`}
                                                size="small"
                                                onClick={() => addFeedback(grammarFinding, FeedbackType.Unhelpful)}
                                                shape="circle"
                                                icon={<DislikeOutlined />}
                                                loading={sendingUnHelpfulFeedback}
                                                disabled={hasUnhelpfulFeedback}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="feedback-text">
                                    <span>Copy</span>
                                    {hasHelpfulFeedback && <span>Thank you for your positive feedback!</span>}
                                    {hasUnhelpfulFeedback && <span>Striving for improvement, Thank you!</span>}
                                    {!hasHelpfulFeedback && !hasUnhelpfulFeedback && <span>Helpful</span>}
                                </div>
                            </span>
                        }
                    >
                        <Text className={`${selectedFixClass} ${highlightClass}`} ref={pushRef}>
                            {match}
                        </Text>
                    </Tooltip>
                );
            });
        });

        return (
            <>
                {contextHolder}
                {totalFixes === 0 && (fixes.length > 0 || analysis.result.length > 0) && (
                    <NotFormattedResult style={style} result={analysis.result} fixes={fixes}></NotFormattedResult>
                )}
                {(totalFixes > 0 || analysis.result.length === 0) && (
                    <div className="formatted-text">
                        <div className="analysis-result-wrapper">
                            <div className={style}>{analysisResultHtml}</div>
                        </div>
                        <Row className="quality-pagination" align="middle" justify="space-between">
                            <Col>
                                {percentAnalyzed < 100 && (
                                    <Tooltip title={`Only the first ${percentAnalyzed}% of the text was analyzed according to the service word limit per one analysis.`}>
                                        <Avatar size={'small'} style={{ backgroundColor: 'red', marginRight: '0.5rem' }}>
                                            {percentAnalyzed}%
                                        </Avatar>
                                    </Tooltip>
                                )}
                                <b>Use Next and Previous buttons for navigation between suggestions</b>
                            </Col>
                            <Col>
                                <Pagination
                                    current={currentFix}
                                    className="pagination"
                                    simple
                                    total={totalFixes}
                                    pageSize={1}
                                    onChange={handlePaginationChange}
                                    itemRender={renderPagination}
                                />
                            </Col>
                        </Row>
                    </div>
                )}
            </>
        );
    } catch (e) {
        return <NotFormattedResult style={style} result={analysis.result}></NotFormattedResult>;
    }
};
