import { AuthToken } from '../auth/dto/auth-token.dto';

const tokenKey = 'AuthToken';

export class LocalStorageService {
    getAuthToken(): AuthToken | null {
        const tokens = localStorage.getItem(tokenKey);
        try {
            return JSON.parse(tokens!);
        } catch (e) {
            localStorage.removeItem(tokenKey);
            return null;
        }
    }

    setAuthToken(token: AuthToken) {
        const tokens = JSON.stringify(token);
        localStorage.setItem(tokenKey, tokens);
    }

    clearAuthToken(): void {
        localStorage.removeItem(tokenKey);
    }
}

export const localStorageService = new LocalStorageService();
