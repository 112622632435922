import React, { useCallback, useState } from 'react';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Flex, Form, Input, message, Typography } from 'antd';
import { authService } from '../../../services/auth/auth.service';
import { useAuthUpdate } from '../../../context/AuthContext';
import { AuthToken } from '../../../services/auth/dto/auth-token.dto';
import { UserDto } from '../../../services/auth/dto/userDto';
import { GoogleLoginButton } from '../../../components/SSO/GoogleAuth/GoogleLoginButton';
import { Rule } from 'antd/es/form';
import { LinkedInPage } from '../../../components/SSO/LinkedInAuth/LinkedInLoginButton';
import { Link } from 'react-router-dom';
import { AuthFormHeader } from '../AuthFormHeader/AuthFormHeader';

const { Text } = Typography;

const LoginFormValidationRules: { [key: string]: Rule[] } = {
    email: [
        { required: true, message: 'Please input your email!' },
        { type: 'email', message: 'The input is not valid Email!' },
    ],
    password: [{ required: true, message: 'Please input your Password!' }],
};

export const LoginPage: React.FC = () => {
    const [resultsAreLoading, setResultsAreLoading] = useState(false);
    const [form] = Form.useForm();
    const setUserToken = useAuthUpdate();
    const [messageApi, contextHolder] = message.useMessage();

    const handleLoginFormSubmit = useCallback(
        (values: any) => {
            let userCredentials: UserDto = { email: values.email, password: values.password };
            setResultsAreLoading(true);

            authService
                .login(userCredentials)
                .then((response: AuthToken) => {
                    messageApi.success('Login successful');
                    setUserToken(response);
                })
                .catch((error) => {
                    messageApi.error(error.message);
                })
                .finally(() => {
                    setResultsAreLoading(false);
                });
        },
        [setUserToken, messageApi],
    );

    return (
        <div className="auth-container">
            {contextHolder}
            <AuthFormHeader />
            <Form form={form} name="normal_login" className="auth-form" initialValues={{ remember: true }} onFinish={handleLoginFormSubmit}>
                <Form.Item name="email" rules={LoginFormValidationRules.email}>
                    <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email" />
                </Form.Item>
                <Form.Item>
                    <Form.Item name="password" rules={LoginFormValidationRules.password}>
                        <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} type="password" placeholder="Password" />
                    </Form.Item>
                    <Flex align={'end'} vertical>
                        <Link to={'/reset-password'}>
                            <Button type={'link'} className="underlined-link">
                                Forgot password
                            </Button>
                        </Link>
                    </Flex>
                </Form.Item>
                <Form.Item>
                    <Button shape="round" type="primary" htmlType="submit" block loading={resultsAreLoading}>
                        Log in
                    </Button>
                </Form.Item>
            </Form>
            <Text style={{ marginBottom: '0.25rem' }}>Or log in with social network</Text>
            <Flex gap={16} style={{ marginBottom: '1rem' }}>
                <GoogleLoginButton />
                <LinkedInPage />
            </Flex>
            <Link to={'/registration'}>
                <Button type={'link'} className="underlined-link">
                    Register now
                </Button>
            </Link>
        </div>
    );
};
