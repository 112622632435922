import React, { useCallback, useEffect, useState } from 'react';
import { SiteMapItemProps } from '../SiteMapTree/models/sitemap-item-props';
import { Button, Card, Checkbox, Col, message, Modal, Row, Space, Spin, Tabs } from 'antd';
import { FileExclamationOutlined, FileProtectOutlined, FileSearchOutlined, FileUnknownOutlined, SyncOutlined } from '@ant-design/icons';
import { analyzerService } from '../../services/analyzer/analyzerService';
import { Analysis, AnalysisStatus, Page } from '../../services/pages/dto/page';
import { pagesService } from '../../services/pages/pagesService';
import { useWsConnection } from '../../context/WsConnectionContext';
import { CheckboxChangeEvent, CheckboxOptionType } from 'antd/es/checkbox';
import { useLookups } from '../../context/LookupContext';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import TextArea from 'antd/es/input/TextArea';
import { contextMaxLength } from '../AddNewProjectForm/AddNewProjectForm';
import { PermissionName, usePermissions } from '../../hooks/usePermissions';
import { usersService } from '../../services/users/users.service';
import { User, UserRole, useUser } from '../../context/UserContext';
import { chargerService } from '../../services/charger/charger.service';
import { renderAnalysisResult } from '../Analyses/RenderAnalyses';
import './PageDetails.scss';
import { useActiveAnalysisIdUpdate } from '../../context/ActiveAnalysisContext';

export const renderAnalysisIcon = (status: AnalysisStatus) => {
    switch (status) {
        case AnalysisStatus.Started:
            return <SyncOutlined spin={true} />;
        case AnalysisStatus.Success:
            return <FileProtectOutlined />;
        case AnalysisStatus.Error:
            return <FileExclamationOutlined />;
        default:
            return <FileUnknownOutlined />;
    }
};

export const PageDetails: React.FC<{ page: SiteMapItemProps }> = ({ page }) => {
    const [analyses, setAnalyses] = useState<Analysis[]>([]);
    const [analysisIsLoading, setAnalysisIsLoading] = useState(false);
    const [tabItems, setTabItems] = useState<any[]>([]);
    const wsConnection = useWsConnection();
    const [messageApi, contextHolder] = message.useMessage();
    const [isAnalysisModalOpen, setIsAnalysisModalOpen] = useState<boolean>(false);
    const [includeSubUrls, setIncludeSubUrls] = useState(false);
    const [pageText, setPageText] = useState(page.text);
    const [pageContext, setPageContext] = useState<string>(page.objectiveContext);
    const [loadedPageId, setLoadedPageId] = useState<number | null>(null);
    const lookups = useLookups();
    const [selectedAnalysesTypes, setSelectedAnalysesTypes] = useState<string[]>([]);
    const [analysisTypes, setAnalysisTypes] = useState<CheckboxOptionType[]>([]);
    const { hasPermission } = usePermissions();
    const user: User | null = useUser();
    const userRole: UserRole | null = user?.role || null;
    const [costOfAnalyse, setCostOfAnalyse] = useState(0);
    const setActiveAnalysisId = useActiveAnalysisIdUpdate();

    useEffect(() => {
        setAnalysisTypes(
            (lookups?.analysisTypes || []).map(
                (x) =>
                    ({
                        value: x.id,
                        label: x.name,
                    } as CheckboxOptionType),
            ),
        );
    }, [setAnalysisTypes, lookups?.analysisTypes]);

    const onCheckAnalysisType = useCallback(
        (checkedValues: CheckboxValueType[]) => {
            let x = checkedValues.map((x) => x.toString());
            setSelectedAnalysesTypes(x);

            if (!loadedPageId) {
                return;
            }

            chargerService.getCostOfAnalyses({ pageId: loadedPageId!, analysesTypes: x, includeSubUrls }).then((res) => {
                setCostOfAnalyse(res);
            });
        },
        [setCostOfAnalyse, setSelectedAnalysesTypes, includeSubUrls, loadedPageId],
    );

    const updateAnalysisInTheList = useCallback(
        (analysis: Analysis) => {
            const updatedAnalyses = analyses.map((oldAnalysis) => {
                if (oldAnalysis.id === analysis.id) {
                    return analysis;
                }
                return oldAnalysis;
            });
            setAnalyses(updatedAnalyses);
        },
        [analyses, setAnalyses],
    );

    const loadPage = useCallback(() => {
        pagesService.getPageById(page.id).then((pageWithAnalyses) => {
            setPageContext(pageWithAnalyses.objectiveContext);
            setLoadedPageId(page.id);
            setAnalyses(pageWithAnalyses?.analyses || []);
            setPageText(pageWithAnalyses.text);
        });
    }, [page, setLoadedPageId, setAnalyses, setPageText, setPageContext]);

    useEffect(() => {
        if (!wsConnection) {
            return;
        }

        const analysisStartedHandler = (event: { message: string; analysis: Analysis }) => {
            // messageApi.success(event.message);
            if (event.analysis.pageId === page.id) {
                loadPage();
            }
        };
        wsConnection.on('AnalysisStarted', analysisStartedHandler);

        const analysisUpdatedHandler = (event: { message: string; analysis: Analysis }) => {
            // messageApi.success(event.message);
            updateAnalysisInTheList(event.analysis);
            if (event.analysis.pageId === page.id) {
                loadPage();
            }
        };
        wsConnection.on('AnalysisEnded', analysisUpdatedHandler);

        const pageUpdatedHandler = (event: { message: string; page: Page }) => {
            if (event.page.id === page.id) {
                loadPage();
            }
        };
        wsConnection.on('PageUpdated', pageUpdatedHandler);

        return () => {
            wsConnection.off('AnalysisStarted', analysisStartedHandler);
            wsConnection.off('AnalysisEnded', analysisUpdatedHandler);
            wsConnection.off('PageUpdated', pageUpdatedHandler);
        };
    }, [wsConnection, messageApi, updateAnalysisInTheList, page.id, loadPage]);

    useEffect(() => {
        if (loadedPageId === page.id) {
            return;
        }
        setAnalysisIsLoading(false);
        setAnalyses(page?.analyses || []);
        loadPage();
    }, [page, loadPage, loadedPageId]);

    const handleUrlToAnaliseSubmit = useCallback(() => {
        const pageId = page?.id;
        if (!pageId) {
            return;
        }

        setAnalysisIsLoading(true);
        analyzerService
            .startAnalysesByPageId(pageId, includeSubUrls, selectedAnalysesTypes, pageContext)
            .then((createdAnalyses: Analysis[]) => {
                setAnalyses([...analyses, ...createdAnalyses]);
            })
            .catch((ex) => {
                message.error(ex.message);
            })
            .finally(() => {
                setAnalysisIsLoading(false);
            });
    }, [page, setAnalyses, analyses, includeSubUrls, selectedAnalysesTypes, pageContext]);

    useEffect(() => {
        const pageTextItem = {
            key: '1',
            label: (
                <span>
                    <FileSearchOutlined />
                    Page text
                </span>
            ),
            children: <div className="formatted-text scrollable-content-page-details">{pageText}</div>,
        };
        const analysisItems = analyses
            .filter((analysis) => analysis.status !== AnalysisStatus.Deprecated)
            .sort((firstAnalysis, secondAnalysis) => {
                return firstAnalysis.updatedAt > secondAnalysis.updatedAt ? -1 : 1;
            })
            .map((analysis, index) => {
                return {
                    key: `${index + 2}-${analysis.id}`,
                    label: (
                        <span>
                            {renderAnalysisIcon(analysis.status)} {analysis.type} analysis
                        </span>
                    ),
                    children: (
                        <Space size={8} direction="vertical">
                            {analysis.result && <>{renderAnalysisResult(analysis, pageText, 'quality-analyses', setAnalyses)}</>}
                            {analysis.status === AnalysisStatus.Started && (
                                <Spin tip="Analysing" size="small">
                                    <div className="content" style={{ paddingBottom: '3rem' }} />
                                </Spin>
                            )}
                        </Space>
                    ),
                };
            });
        setTabItems([pageTextItem, ...analysisItems]);
    }, [page, analyses, analysisIsLoading, handleUrlToAnaliseSubmit, pageText]);

    const showAnalysisModal = useCallback(() => {
        setIsAnalysisModalOpen(true);
    }, [setIsAnalysisModalOpen]);

    const resetForm = useCallback(() => {
        setIsAnalysisModalOpen(false);
        setSelectedAnalysesTypes([]);
        setIncludeSubUrls(false);
        setCostOfAnalyse(0);
    }, [setIsAnalysisModalOpen, setSelectedAnalysesTypes, setIncludeSubUrls, setCostOfAnalyse]);

    const handleAnalysisSubmitModal = useCallback(() => {
        usersService
            .getAvailableTokens()
            .then((tokens) => {
                if (tokens < costOfAnalyse && userRole !== UserRole.Admin) {
                    message.error('Not enough tokens to start analysis');
                } else {
                    handleUrlToAnaliseSubmit();
                    resetForm();
                }
            })
            .catch((ex) => {
                message.error(ex.message);
            });
    }, [handleUrlToAnaliseSubmit, costOfAnalyse, resetForm, userRole]);

    const handleAnalysisCancelModal = useCallback(() => {
        resetForm();
    }, [resetForm]);

    const handleIncludeSubUrlsToggle = useCallback(
        (e: CheckboxChangeEvent) => {
            setIncludeSubUrls(e.target.checked);

            if (!loadedPageId) {
                return;
            }

            let subUrls = e.target.checked;

            chargerService
                .getCostOfAnalyses({
                    pageId: loadedPageId,
                    analysesTypes: selectedAnalysesTypes,
                    includeSubUrls: subUrls,
                })
                .then((res) => {
                    setCostOfAnalyse(res);
                });
        },
        [setIncludeSubUrls, setCostOfAnalyse, selectedAnalysesTypes, loadedPageId],
    );

    const onPageContextChange = useCallback(
        (e: any) => {
            setPageContext(e.target.value);
        },
        [setPageContext],
    );

    useEffect(() => {
        setActiveAnalysisId(null);
    }, [tabItems, setActiveAnalysisId]);

    const handleActiveAnalysisChange = useCallback(
        (activeTabKey: string | number) => {
            // active tab key was generated like `${index + 2}-${analysis.id}`
            let selectedAnalysisId: string | number | null = activeTabKey.toString().split('-')[1] || null;
            selectedAnalysisId = selectedAnalysisId ? Number.parseInt(selectedAnalysisId) : null;
            setActiveAnalysisId(selectedAnalysisId);
        },
        [setActiveAnalysisId],
    );

    return (
        <Card className="analyses-container">
            {contextHolder}
            <Row justify="start" align="middle">
                <Col span={3}>
                    <Button type="primary" onClick={showAnalysisModal} loading={analysisIsLoading}>
                        Analyse
                    </Button>
                    <Modal title="Start a new analysis" open={isAnalysisModalOpen} onOk={handleAnalysisSubmitModal} onCancel={handleAnalysisCancelModal} okText="Start analysis">
                        {hasPermission(PermissionName.ContextEdit) && (
                            <div className="form-item">
                                Page purpose:
                                <TextArea
                                    showCount
                                    maxLength={contextMaxLength}
                                    value={pageContext}
                                    onChange={onPageContextChange}
                                    autoSize={{ minRows: 3, maxRows: 5 }}
                                    placeholder="Please describe the objective that this page is intended to achieve."
                                />
                            </div>
                        )}

                        <div className="form-item">
                            Analysis types:
                            <Checkbox.Group options={analysisTypes} value={selectedAnalysesTypes} onChange={onCheckAnalysisType} />
                        </div>
                        <Checkbox checked={includeSubUrls} onChange={handleIncludeSubUrlsToggle}>
                            Include all sub pages to scan and analyse
                        </Checkbox>
                        <div>Cost of analyse: {costOfAnalyse}</div>
                    </Modal>
                </Col>
                <Col span={21}>
                    <div>
                        <b>{page.title}</b>
                        <div>
                            <a href={page.url} rel="noreferrer" target="_blank">
                                {page.url}
                            </a>
                        </div>
                    </div>
                </Col>
            </Row>
            <Tabs defaultActiveKey="1" items={tabItems} onChange={handleActiveAnalysisChange} />
        </Card>
    );
};
