export interface AnalysisFeedbackDto {
    analysisId: number;
    type: FeedbackType;
    mistake: string;
    fix: string;
    fullSentence: string;
}

export enum FeedbackType {
    Helpful = 'Helpful',
    Unhelpful = 'Unhelpful',
}
