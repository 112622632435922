import { AnalysisType } from '../../pages/dto/page';

export enum AnalysisInputType {
    Text = 'text',
    HTML = 'html',
}

export interface AnalysisPrompt {
    analysisType: AnalysisType;
    promptTemplate: string;
    inputType: AnalysisInputType;
}

export enum PromptConfigurationLevel {
    Application = 'Application',
    User = 'User',
    Project = 'Project',
}

export interface PromptConfigurationDto {
    companyPromptTemplate: string;
    sitePromptTemplate: string;
    pagePromptTemplate: string;
    combinedPromptTemplate: string;
    analysisPrompts: AnalysisPrompt[];
    level: PromptConfigurationLevel;
}
