import React from 'react';
import { ConfigProvider, Flex, Layout } from 'antd';
import { Outlet } from 'react-router-dom';
import './AuthLayout.scss';
import { darkThemeConfig, lightThemeConfig } from '../themeConfigs';
import { AppFooter } from '../AppFooter/AppFooter';
import { SiteLogo } from '../SiteLogo/SiteLogo';
import { Feedback } from '../../Feedback/Feedback';
import { UserMenu } from './UserMenu/UserMenu';

const { Header, Content } = Layout;

export const AuthLayout: React.FC<{ includeFooter?: boolean }> = ({ includeFooter }) => {
    return (
        <div className="AppLayout">
            <ConfigProvider theme={lightThemeConfig}>
                <Layout style={{ minHeight: '100vh' }}>
                    <Header className="AppHeader">
                        <Flex align="center" justify="space-between">
                            <SiteLogo />
                            <UserMenu />
                        </Flex>
                    </Header>
                    <Content>
                        <Outlet />
                    </Content>
                    <Feedback />
                    {includeFooter && (
                        <ConfigProvider theme={darkThemeConfig}>
                            <AppFooter />
                        </ConfigProvider>
                    )}
                </Layout>
            </ConfigProvider>
        </div>
    );
};

AuthLayout.defaultProps = {
    includeFooter: false,
};
