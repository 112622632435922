import { User, UserRole, useUser } from '../context/UserContext';

export enum PermissionName {
    ProjectCreate = 'Project.create',
    ProjectEdit = 'Project.edit',
    ProjectDelete = 'Project.delete',
    GlobalPromptConfigEdit = 'AppSettings.GlobalPromptConfig.edit',
    ContextEdit = 'Context.edit'
}

const UserPermissionsByRole = {
    [PermissionName.ProjectCreate]: [UserRole.FreeTrialUser, UserRole.PaidCustomer, UserRole.Admin],
    [PermissionName.ProjectEdit]: [UserRole.Admin],
    [PermissionName.ProjectDelete]: [UserRole.Admin],
    [PermissionName.GlobalPromptConfigEdit]: [UserRole.Admin],
    [PermissionName.ContextEdit]: [UserRole.Admin, UserRole.PaidCustomer]
};

// export type PermissionName = keyof typeof UserPermissionsByRole;

export const usePermissions = () => {
    const user: User | null = useUser();
    const userRole: UserRole | null = user?.role || null;

    const hasPermission = (permissionName: PermissionName): boolean => {
        if (!userRole) {
            return false;
        }
        return UserPermissionsByRole[permissionName].includes(userRole);
    };

    return { hasPermission };
};
