import { Rule } from 'antd/es/form';
import { Button, Flex, Form, Input, message } from 'antd';
import React, { useCallback, useState } from 'react';
import { authService } from '../../../services/auth/auth.service';
import { passwordNotMatchPolicyError, passwordPolicy } from '../Policy';
import { Link, useSearchParams } from 'react-router-dom';
import { useAuthUpdate } from '../../../context/AuthContext';
import { AuthFormHeader } from '../AuthFormHeader/AuthFormHeader';

const ChangePasswordFormValidationRules: { [key: string]: Rule[] } = {
    password: [
        {
            required: true,
            message: 'Please input your password!',
        },
        {
            message: passwordNotMatchPolicyError,
            validator: (_, value) => {
                if (passwordPolicy.test(value)) {
                    return Promise.resolve();
                } else {
                    return Promise.reject('Some message here');
                }
            },
        },
    ],
    confirm: [
        {
            required: true,
            message: 'Please confirm your password!',
        },
        ({ getFieldValue }) => ({
            validator(_, value) {
                if (!passwordPolicy.test(value)) {
                    return Promise.reject(passwordNotMatchPolicyError);
                }

                if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                }

                return Promise.reject(new Error('The new password that you entered do not match!'));
            },
        }),
    ],
};

export const ChangePasswordPage: React.FC = () => {
    const [searchParams] = useSearchParams();
    const [form] = Form.useForm();
    const [resultsAreLoading, setResultsAreLoading] = useState(false);
    const setUserToken = useAuthUpdate();
    const [messageApi, contextHolder] = message.useMessage();

    const handleChangePasswordFormSubmit = useCallback(
        (values: any) => {
            const token = searchParams.get('token');
            if (!token) {
                messageApi.error('Token not provided for resetting password');
                return;
            }

            const { password } = values;
            setResultsAreLoading(true);
            authService
                .changePassword(password, token)
                .then((response) => {
                    messageApi.success('Password changed', 3).then(() => {
                        setUserToken(response);
                    });
                })
                .catch((error) => {
                    messageApi.error(error.message);
                })
                .finally(() => {
                    setResultsAreLoading(false);
                });
        },
        [setUserToken, searchParams, messageApi],
    );

    return (
        <div className="auth-container">
            {contextHolder}
            <AuthFormHeader />
            <Form form={form} name="register" onFinish={handleChangePasswordFormSubmit} className="auth-form" scrollToFirstError>
                <Form.Item name="password" rules={ChangePasswordFormValidationRules.password} hasFeedback>
                    <Input.Password placeholder="New Password" />
                </Form.Item>

                <Form.Item name="confirm" dependencies={['password']} hasFeedback rules={ChangePasswordFormValidationRules.confirm}>
                    <Input.Password placeholder="Confirm Password" />
                </Form.Item>
                <Form.Item>
                    <Flex align={'center'} vertical gap={'large'}>
                        <Button block shape="round" type="primary" htmlType="submit" loading={resultsAreLoading}>
                            Update password
                        </Button>
                        <Link to={'/login'}>
                            <Button type={'link'} className="underlined-link">
                                Go back to login
                            </Button>
                        </Link>
                    </Flex>
                </Form.Item>
            </Form>
        </div>
    );
};
