import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Form, Input, Modal, Row } from 'antd';
import { PromptConfigurationDto } from '../../services/settings/dto/prompt-configuration.dto';
import './EditPromptConfigurationButton.scss';

const { TextArea } = Input;
/* eslint-disable no-template-curly-in-string */
const combinedPromptTooltip =
    'Use ${companyPromptPart}, ${sitePromptPart}, ${pagePromptPart}, ${analysisQuestion} and ${analysisInput} variables.' +
    ' Each variable be included only if context value is set.';
const companyPromptTemplateTooltip = 'Use ${companyContext} variable';
const sitePromptTemplateTooltip = 'Use ${siteContext} variable';
const pagePromptTemplateTooltip = 'Use ${pageContext} variable';
/* eslint-enable no-template-curly-in-string */

export interface EditPromptConfigurationProps {
    initialPromptConfig: PromptConfigurationDto;
    onPromptConfigUpdated: Function;
    editPromptConfigButtonText: string;
}

export const EditPromptConfigurationButton: React.FC<EditPromptConfigurationProps> = ({ initialPromptConfig, onPromptConfigUpdated, editPromptConfigButtonText }) => {
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
    const [form] = Form.useForm();
    const textareaMaxLength = 600;

    const showModal = useCallback(() => {
        setModalIsOpen(true);
    }, [setModalIsOpen]);

    const handleCancel = useCallback(() => {
        setModalIsOpen(false);
    }, [setModalIsOpen]);

    useEffect(() => {
        const { companyPromptTemplate, sitePromptTemplate, pagePromptTemplate, combinedPromptTemplate, analysisPrompts } = initialPromptConfig;
        form.setFieldsValue({
            companyPromptTemplate,
            sitePromptTemplate,
            pagePromptTemplate,
            combinedPromptTemplate,
            analysisPrompts,
        });
    }, [form, initialPromptConfig]);

    const handleFormSubmit = useCallback(() => {
        const updatedPromptConfig = form.getFieldsValue();
        onPromptConfigUpdated({ ...initialPromptConfig, ...updatedPromptConfig });
        setModalIsOpen(false);
    }, [setModalIsOpen, onPromptConfigUpdated, form, initialPromptConfig]);

    return (
        <>
            <Button block type="link" onClick={showModal}>
                {editPromptConfigButtonText}
            </Button>

            <Modal title="ChatGPT prompt configuration" open={modalIsOpen} onOk={handleFormSubmit} onCancel={handleCancel} width={800} centered okText={'Save changes'}>
                <Form form={form} name="prompt-config" layout="vertical" size="small" onFinish={handleFormSubmit} style={{ maxWidth: 800, paddingBottom: '1rem' }}>
                    <Form.Item name="companyPromptTemplate" label="Company Prompt Template" tooltip={companyPromptTemplateTooltip} rules={[{ required: true }]}>
                        <TextArea showCount maxLength={textareaMaxLength} rows={2} />
                    </Form.Item>
                    <Form.Item name="sitePromptTemplate" label="Site Prompt Template" tooltip={sitePromptTemplateTooltip} rules={[{ required: true }]}>
                        <TextArea showCount maxLength={textareaMaxLength} rows={2} />
                    </Form.Item>
                    <Form.Item name="pagePromptTemplate" label="Page Prompt Template" tooltip={pagePromptTemplateTooltip} rules={[{ required: true }]}>
                        <TextArea showCount maxLength={textareaMaxLength} rows={2} />
                    </Form.Item>
                    <p>Analysis Question based on type:</p>
                    <Form.List name="analysisPrompts">
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(({ key, name, ...restField }) => (
                                    <Row key={key} gutter={8}>
                                        <Col span={3}>
                                            <Form.Item {...restField} name={[name, 'analysisType']}>
                                                <Input disabled={true} bordered={false} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={21}>
                                            <Form.Item {...restField} name={[name, 'promptTemplate']} rules={[{ required: true }]}>
                                                <TextArea showCount maxLength={textareaMaxLength} rows={1} placeholder="Prompt Template" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                ))}
                            </>
                        )}
                    </Form.List>

                    <Form.Item name="combinedPromptTemplate" label="Combined Prompt Template" tooltip={combinedPromptTooltip} rules={[{ required: true }]}>
                        <TextArea showCount maxLength={textareaMaxLength} rows={2} />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};
