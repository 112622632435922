import jwt_decode from 'jwt-decode';
import { apiUrl } from '../../App.config';
import { localStorageService } from '../local-storage/local-storage.service';
import dayjs from 'dayjs';
import { AuthToken } from '../auth/dto/auth-token.dto';
import { UserRole } from '../../context/UserContext';
import { message } from 'antd';

type HTTPMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';

export type RequestOptions<Body> = {
    method?: HTTPMethod;
    body?: Body;
    signal?: AbortSignal;
};

export type ApiRequestOptions<Body> = {
    body?: Body;
    signal?: AbortSignal;
};

export type DecodedToken = {
    exp: number;
    sub: number;
    username: string;
    role: UserRole;
};

export class ApiService {
    public get = async <Response, Body>(url: string | URL, options: RequestOptions<Body> = {}) => {
        return this.request<Response, Body>(url.toString(), {
            ...options,
            method: 'GET',
        });
    };

    public post = async <Response, Body>(url: string | URL, options: RequestOptions<Body> = {}) => {
        return this.request<Response, Body>(url.toString(), {
            ...options,
            method: 'POST',
        });
    };

    public delete = async <Response, Body>(url: string | URL, options: RequestOptions<Body> = {}) => {
        return this.request<Response, Body>(url.toString(), {
            ...options,
            method: 'DELETE',
        });
    };

    public patch = async <Response, Body>(url: string | URL, options: RequestOptions<Body> = {}) => {
        return this.request<Response, Body>(url.toString(), {
            ...options,
            method: 'PATCH',
        });
    };

    public request = async <Response, Body>(url: string, options: RequestOptions<Body> = {}): Promise<Response> => {
        const { method, body, signal } = options;

        let accessToken = localStorageService.getAuthToken()?.access_token;

        if (accessToken) {
            await this.validateToken(accessToken);
            accessToken = localStorageService.getAuthToken()?.access_token;
        }

        const response = await fetch(`${apiUrl}/${url}`, {
            mode: 'cors',
            method: method ?? 'GET',
            body: JSON.stringify(body),
            headers: {
                Accepts: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
            },
            signal,
        });

        const contentType = response.headers.get('Content-Type');
        const isJSON = contentType?.toLowerCase().startsWith('application/json');
        const result = isJSON ? await response.json() : await response.text();

        if (!response.ok) {
            if (response.status === 401 && accessToken) {
                localStorageService.clearAuthToken();
                window.location.reload();
            }

            if (response.status === 403) {
                message.error(result.message);
                return result;
            }

            throw new Error(result.message);
        }

        return result;
    };

    public prepareQueryParamsString(queryParams: any): string {
        if (!queryParams) {
            return '';
        }
        const keyValuePairs = [];
        for (const paramName in queryParams) {
            keyValuePairs.push(encodeURIComponent(paramName) + '=' + encodeURIComponent(queryParams[paramName]));
        }
        return keyValuePairs.join('&');
    }

    private async validateToken(accessToken: string) {
        let decodedToken: DecodedToken = jwt_decode(accessToken!);
        const isAccessTokenExpired = dayjs.unix(decodedToken.exp).diff(dayjs()) < 1;

        if (isAccessTokenExpired) {
            let authTokens = await this.refreshToken();
            localStorageService.setAuthToken(authTokens);
            window.dispatchEvent(new Event('localStorage'));
        }
    }

    private async refreshToken(): Promise<AuthToken> {
        let refreshToken = localStorageService.getAuthToken()?.refresh_token;

        const resp = await fetch(`${apiUrl}/auth/refresh-token`, {
            mode: 'cors',
            method: 'GET',
            headers: {
                Accepts: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${refreshToken}`,
            },
        }).then(function (res) {
            return res.json();
        });

        return resp;
    }
}

export const apiService = new ApiService();
