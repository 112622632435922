import { AnalysisFeedbackDto } from '../../analyzer/dto/analysis-feedback.dto';
import { Project } from '../../projects/dto/project';

export interface Page {
    id: number;
    url: string;
    title: string;
    text: string;
    createdAt: string;
    updatedAt: string;
    projectId: number;
    analyses: Analysis[];
    status: PageStatus;
    objectiveContext: string;
    urlStatusCode: number;
    urlStatusMessage: string;
    project?: Project;
}

export enum PageStatus {
    Draft = 'Draft',
    Crawling = 'Crawling',
    Crawled = 'Crawled',
    Analysing = 'Analysing',
    Analysed = 'Analysed',
    Error = 'Error',
}

export enum AnalysisStatus {
    Draft = 'Draft',
    Started = 'Started',
    Success = 'Success',
    Error = 'Error',
    Deprecated = 'Deprecated',
}
export enum AnalysisType {
    LinkChecker = 'Link Checker',
    Marketing = 'Marketing',
    SEO = 'SEO',
    Quality = 'Quality',
}

export interface Analysis {
    id: number;
    status: AnalysisStatus;
    type: AnalysisType;
    result: string;
    pageId: number;
    page: Page;
    createdAt: string;
    updatedAt: string;
    question: string;
    analysisFeedbacks: AnalysisFeedbackDto[];
    pageWordsCount?: number;
    requiredOpenAITokensToInputAllPageText?: number;
    analyzedWordsCount?: number;
    usedOpenAITokensToInputLimitedAnalysisContent?: number;
    contentToAnalyze?: string;
    usedOpenAITokensToInputAnalysisPrompt?: number;
    usedOpenAITokensToOutputAnalysisResults?: number;
}
