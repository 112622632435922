import { Rule } from 'antd/es/form';
import { Button, Flex, Form, Input, message } from 'antd';
import React, { useCallback, useState } from 'react';
import { authService } from '../../../services/auth/auth.service';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { AuthFormHeader } from '../AuthFormHeader/AuthFormHeader';

const ResetPasswordFormValidationRules: { [key: string]: Rule[] } = {
    email: [
        {
            type: 'email',
            message: 'The input is not valid Email!',
        },
        {
            required: true,
            message: 'Please input your Email!',
        },
    ],
};

export const ResetPasswordPage: React.FC = () => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [resultsAreLoading, setResultsAreLoading] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();

    const handleResetPasswordFormSubmit = useCallback(
        (values: any) => {
            let email = values.email;
            setResultsAreLoading(true);
            authService
                .resetPassword(email)
                .then((response) => {
                    const successMessage = 'Check your email. If you entered the correct information, you will receive the password reset information.';
                    messageApi.success(successMessage, 6).then(() => {
                        navigate('/login');
                    });
                })
                .catch((error) => {
                    messageApi.error(error.message);
                })
                .finally(() => {
                    setResultsAreLoading(false);
                });
        },
        [navigate, messageApi],
    );

    return (
        <div className="auth-container">
            {contextHolder}
            <AuthFormHeader />
            <Form className="auth-form" form={form} name="register" onFinish={handleResetPasswordFormSubmit} scrollToFirstError>
                <Form.Item name="email" rules={ResetPasswordFormValidationRules.email}>
                    <Input placeholder="Email" />
                </Form.Item>
                <Form.Item style={{ marginBottom: 0 }}>
                    <Flex align={'center'} vertical gap={'large'}>
                        <Button shape="round" block type="primary" htmlType="submit" loading={resultsAreLoading}>
                            Send
                        </Button>
                        <Link to={'/login'}>
                            <Button type={'link'} className="underlined-link">
                                Go back to login
                            </Button>
                        </Link>
                    </Flex>
                </Form.Item>
            </Form>
        </div>
    );
};
