import { Table } from "antd";
import { Analysis } from "../../../services/pages/dto/page";
import './LinkChecker.scss';

export enum LinkState {
    OK = 'OK',
    BROKEN = 'BROKEN',
    SKIPPED = 'SKIPPED',
}

interface CheckedLink {
    url: string;
    status: number;
    state: LinkState;
}

function capitalizeFirstLetter(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export const LinkChecker: React.FC<{ analysis: Analysis }> = ({ analysis }) => {
    let result: string | { links: CheckedLink[] } = analysis.result;
    try {
        const linkCheckerTableColumns = [
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                width: '12%',
                render: (status: number) => <span style={{ color: status === 200 ? 'green' : 'red' }}>{status}</span>,
            },
            {
                title: 'State',
                dataIndex: 'state',
                key: 'state',
                width: '12%',
                render: (state: LinkState) => capitalizeFirstLetter(state),
            },
            {
                title: 'Url',
                dataIndex: 'url',
                key: 'url',
                ellipsis: true,
                render: (url: string) => (
                    <a href={url} target={'_blank'} rel="noreferrer">
                        {url}
                    </a>
                ),
            },
        ];
        result = JSON.parse(result) as { links: CheckedLink[] };
        const brokenLinks = result.links.filter(({ state }) => state === LinkState.BROKEN);
        const skippedLinks = result.links.filter(({ state }) => state === LinkState.SKIPPED);
        const okLinks = result.links.filter(({ state }) => state === LinkState.OK);
        const links = [...brokenLinks, ...okLinks, ...skippedLinks];
        return <Table
            rowKey={'url'}
            columns={linkCheckerTableColumns}
            dataSource={links}
            className="scrollable-content-page"
            pagination={{ pageSize: 6, showSizeChanger: false }}
        />
    } catch (e) {
        return <div className="formatted-text">{analysis.result}</div>;
    }
}
