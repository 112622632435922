import { Page } from '../../pages/dto/page';

export enum ProjectStatus {
    Created = 'Created',
    Scanning = 'Scanning',
    Scanned = 'Scanned',
    Analysing = 'Analysing',
    Analysed = 'Analysed',
}

export interface Project {
    id: number | null;
    name: string;
    createdAt: Date;
    updatedAt: Date;
    status: ProjectStatus;
    siteObjectiveContext: string;
    companyContext: string;
    url?: string;
}

export interface ProjectWithPages extends Project {
    pages: Page[];
}
