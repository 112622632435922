import React, { useCallback } from 'react';
import { LogoutOutlined } from '@ant-design/icons';
import { authService } from '../../../../services/auth/auth.service';
import { useAuthUpdate } from '../../../../context/AuthContext';
import { Button } from 'antd';

export const LogOutButton: React.FC = () => {
    const setUserToken = useAuthUpdate();

    const handleLogoutClick = useCallback(() => {
        authService.logout().finally(() => {
            setUserToken({ token: null });
        });
    }, [setUserToken]);

    return (
        <Button icon={<LogoutOutlined />} block type="link" onClick={handleLogoutClick} style={{ padding: '0 0.75rem', height: 'auto', textAlign: 'left' }}>
            Log out
        </Button>
    );
};
