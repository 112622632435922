import React from 'react';
import { lightThemeConfig } from '../../../components/Layouts/themeConfigs';
import { ConfigProvider, Layout } from 'antd';
import { FrequentAskedQuestions, FrequentlyAskedQuestion } from '../../../components/FrequentAskedQuestions/FrequentAskedQuestions';

const frequentlyAskedQuestions: FrequentlyAskedQuestion[] = [
    {
        question: 'I heard AI makes mistakes sometimes. Is PerfectSite perfect?',
        answer: 'The application of PerfectSite’s AI analysis is very good, but is not perfect. What PerfectSite does superbly is save time and effort while making your site better, with just a little help from a human friend. So, yes, do read PerfectSite’s suggestions and decide for yourself if you want to implement each one. And skip the manual reading and re-reading every time a structure or content change happens on your site until you get a clean bill of health from PerfectSite. At this point, your site will be way ahead of the game and won’t embarrass you, but…sure, do a final human pass when time allows.',
    },
    {
        question: 'If it’s a beta, why am I limited to a trial of 5 URLs?',
        answer: 'We want to give as many people as possible the chance to try PerfectSite and send us Beta feedback. AI processing is still quite intensive, and if you multiply a large number of non-paying Beta testers by unlimited processing cycles, well…you see the financial problem, right? Once we get out of Beta and into Production, the paid subscription packages will be affordable and let you perform hundreds or thousands of page scans per month.',
    },
    {
        question: 'Why do I see text corrections when the text I see looks fine?',
        answer: 'We analyze the entire text of the page, which includes the mobile and desktop versions. Text that is not visible on the desktop may be visible on a mobile device. That’s why all versions of the page will be affected. This is an area of continuing development for us as we work to reduce false positives.',
    },
    {
        question: 'Sometimes, I may see the same suggestion highlighted multiple times.',
        answer: 'PerfectSite highlights the suspected erroneous text to ensure that you can easily identify and fix any issues. Currently, if the AI sees an error, we highlight any possible place where that text recurs. In some cases, what is an actual error in one case is fine in another. For example, Title Case may be an error in a sentence but perfectly fine on a menu bar, but both will be highlighted.   As PerfectSite learns more and more, it will get better at making these distinctions, and your feedback is an important part of that journey.',
    },
    {
        question: 'Why can’t I analyze pages with a very large amount of text?',
        answer: 'The free Beta limits the text size for economic reasons, similar to the 5-page limitation (above). The paid subscription version will have no such restriction.',
    },
    // {
    //     question: 'Question 4?',
    //     answer: 'Answer 4',
    // },
];

const faqDescription =
    'Our goal is to provide you with the best possible user experience, and we take that responsibility seriously. PerfectSite detects subtle and egregious errors, but we know it still has limitations and makes mistakes. We’re working continuously to increase accuracy and minimize the amount of human time required for cross-checking. Your feedback is a key part of that journey.\n' +
    'Meanwhile, below is a current list of known issues we’re working on and questions people ask:';

export const FAQPage: React.FC = () => {
    return (
        <div>
            <ConfigProvider theme={lightThemeConfig}>
                <Layout>
                    <div className="fluid-content">
                        <FrequentAskedQuestions questions={frequentlyAskedQuestions} description={faqDescription}></FrequentAskedQuestions>
                    </div>
                </Layout>
            </ConfigProvider>
        </div>
    );
};
