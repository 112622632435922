import { Button, Checkbox, Flex, Form, Input, message, Typography } from 'antd';
import React, { useCallback, useState } from 'react';
import { authService } from '../../../services/auth/auth.service';
import { Rule } from 'rc-field-form/lib/interface';
import { UserDto } from '../../../services/auth/dto/userDto';
import { passwordNotMatchPolicyError, passwordPolicy } from '../Policy';
import { useNavigate } from 'react-router';
import { AuthFormHeader } from '../AuthFormHeader/AuthFormHeader';
import { Link } from 'react-router-dom';
import { GoogleLoginButton } from '../../../components/SSO/GoogleAuth/GoogleLoginButton';
import { LinkedInPage } from '../../../components/SSO/LinkedInAuth/LinkedInLoginButton';

const { Text } = Typography;

const RegistrationFormValidationRules: { [key: string]: Rule[] } = {
    email: [
        {
            type: 'email',
            message: 'The input is not valid Email!',
        },
        {
            required: true,
            message: 'Please input your Email!',
        },
    ],
    password: [
        {
            required: true,
            message: 'Please input your password!',
        },
        {
            message: passwordNotMatchPolicyError,
            validator: (_, value) => {
                if (passwordPolicy.test(value)) {
                    return Promise.resolve();
                } else {
                    return Promise.reject('Some message here');
                }
            },
        },
    ],
    confirm: [
        {
            required: true,
            message: 'Please confirm your password!',
        },
        ({ getFieldValue }) => ({
            validator(_, value) {
                if (!passwordPolicy.test(value)) {
                    return Promise.reject(passwordNotMatchPolicyError);
                }

                if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                }

                return Promise.reject(new Error('The new password that you entered do not match!'));
            },
        }),
    ],
    terms: [
        {
            required: true,
            transform: (value) => value || undefined,
            type: 'boolean',
            message: 'Please accept Privacy Policy and Terms & Conditions!',
        },
    ],
};

export const RegistrationPage: React.FC = () => {
    const [form] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();
    const navigate = useNavigate();

    const [resultsAreLoading, setResultsAreLoading] = useState(false);

    const handleRegistrationFormSubmit = useCallback(
        (values: any) => {
            let userCredentials: UserDto = { email: values.email, password: values.password };
            setResultsAreLoading(true);
            authService
                .registerUser(userCredentials)
                .then(() => {
                    messageApi.success('Successful registration. Confirm your email please.', 3).then(() => {
                        navigate('/login');
                    });
                })
                .catch((error) => {
                    messageApi.error(error.message);
                })
                .finally(() => {
                    setResultsAreLoading(false);
                });
        },
        [messageApi, navigate],
    );

    return (
        <div className="auth-container">
            {contextHolder}
            <AuthFormHeader />
            <Form form={form} name="register" onFinish={handleRegistrationFormSubmit} className="auth-form" scrollToFirstError>
                <Form.Item name="email" rules={RegistrationFormValidationRules.email}>
                    <Input placeholder="Email" />
                </Form.Item>

                <Form.Item name="password" rules={RegistrationFormValidationRules.password} hasFeedback>
                    <Input.Password placeholder="Password" />
                </Form.Item>

                <Form.Item name="confirm" dependencies={['password']} hasFeedback rules={RegistrationFormValidationRules.confirm}>
                    <Input.Password placeholder="Confirm Password" />
                </Form.Item>

                <Form.Item name="terms" valuePropName={'checked'} initialValue={true} rules={RegistrationFormValidationRules.terms}>
                    <Checkbox style={{ lineHeight: 1 }}>
                        I’ve read and accept{' '}
                        <Link to={'/privacy-policy'} target="_blank">
                            <Button type={'link'} className="underlined-link" style={{ padding: 0, height: '1rem' }}>
                                Privacy Policy
                            </Button>
                        </Link>{' '}
                        and{' '}
                        <Link to={'/terms-and-conditions'} target="_blank">
                            <Button type={'link'} className="underlined-link" style={{ padding: 0, height: '1rem' }}>
                                Terms & Conditions
                            </Button>
                        </Link>
                    </Checkbox>
                </Form.Item>

                <Form.Item>
                    <Flex align={'center'} vertical gap={'large'}>
                        <Button block shape="round" type="primary" htmlType="submit" loading={resultsAreLoading}>
                            Register
                        </Button>
                        <Link to={'/login'}>
                            <Button type={'link'} className="underlined-link">
                                Go back to login
                            </Button>
                        </Link>
                    </Flex>
                </Form.Item>
            </Form>
            <Text style={{ marginBottom: '0.25rem' }}>Or register with social network</Text>
            <Flex gap={16}>
                <GoogleLoginButton />
                <LinkedInPage />
            </Flex>
        </div>
    );
};
