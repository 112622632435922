import React from 'react';
import './App.scss';

import { AppRouter } from './AppRouter';
import { AuthProvider } from './context/AuthContext';
import { WsConnectionProvider } from './context/WsConnectionContext';
import { LookupsProvider } from './context/LookupContext';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { googleAuthClientId } from './App.config';
import { AppSettingsProvider } from './context/AppSettingsContext';
import { UserProvider } from './context/UserContext';
import { UserSettingsProvider } from './context/UserSettingsContext';
import { ActiveProjectProvider } from './context/ActiveProjectContext';
import { ActiveAnalysisProvider } from './context/ActiveAnalysisContext';

export const App: React.FC = () => {
    return (
        <GoogleOAuthProvider clientId={googleAuthClientId}>
            <AppSettingsProvider>
                <AuthProvider>
                    <UserProvider>
                        <UserSettingsProvider>
                            <WsConnectionProvider>
                                <LookupsProvider>
                                    <ActiveProjectProvider>
                                        <ActiveAnalysisProvider>
                                            <AppRouter />
                                        </ActiveAnalysisProvider>
                                    </ActiveProjectProvider>
                                </LookupsProvider>
                            </WsConnectionProvider>
                        </UserSettingsProvider>
                    </UserProvider>
                </AuthProvider>
            </AppSettingsProvider>
        </GoogleOAuthProvider>
    );
};
