import { Button, Col, Layout, Menu, MenuProps, Row } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { SiteLogo } from '../SiteLogo/SiteLogo';
import { headerHeight } from '../themeConfigs';
const { Header } = Layout;

const navigation: MenuProps['items'] = [
    {
        label: (
            <Link to={'/'}>
                <Button type={'link'} className="strong-btn">
                    Home
                </Button>
            </Link>
        ),
        key: '/'
    },
    {
        label: (
            <Link to={'/faq'}>
                <Button type={'link'} className="strong-btn">
                    FAQ
                </Button>
            </Link>
        ),
        key: '/faq'
    },
];

export const UnAuthHeader: React.FC = () => {
    const location = useLocation();
    const [currentPathName, setCurrentPathName] = useState(location.pathname);

    useEffect(() => {
        setCurrentPathName(location.pathname);
    }, [location]);

    const onClick: MenuProps['onClick'] = (e) => {
        setCurrentPathName(e.key);
    };

    return (
        <Header
            style={{
                position: 'sticky',
                top: 0,
                zIndex: 1,
                display: 'flex',
                alignItems: 'center',
                height: `${headerHeight}px`,
            }}
        >
            <Row align="middle" justify="space-between" style={{ width: '100%' }}>
                <Col span={8}>
                    <SiteLogo />
                </Col>
                <Col span={16}>
                    <Row align="middle" justify="end">
                        <Menu
                            disabledOverflow={true}
                            className='navigation-dark'
                            onClick={onClick}
                            selectedKeys={[currentPathName]}
                            mode="horizontal"
                            items={navigation}
                            theme="dark"
                            style={{ borderBottom: "none", lineHeight: "0px" }}
                        />
                        <Col>
                            <Link to={'/login'}>
                                <Button type="primary" shape="round" style={{ marginLeft: '1rem' }}>
                                    Login
                                </Button>
                            </Link>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Header>
    );
};
