import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Form, Input, Layout, Row, Space, Typography, notification, Spin, Flex } from 'antd';
import { Rule } from 'antd/es/form';
import { Analysis, AnalysisType } from '../../services/pages/dto/page';
import { analyzerService } from '../../services/analyzer/analyzerService';
import { Project, ProjectWithPages } from '../../services/projects/dto/project';
import { SiteMapItemProps } from '../../components/SiteMapTree/models/sitemap-item-props';
import { projectsService } from '../../services/projects/projectsService';
import { BehaviorSubject, debounceTime } from 'rxjs';
import { useWsConnection } from '../../context/WsConnectionContext';
import { TrialPageDetails } from './TrialPageDetails';
import './TrialPageDetails.scss';
import { useUserSettings } from '../../context/UserSettingsContext';
import { urlPolicy, urlPolicyError } from '../Auth/Policy';
import './TrialPage.scss';
import { ProjectsMenu } from '../../components/ProjectsMenu/ProjectsMenu';
import { useActiveProjectIdUpdate } from '../../context/ActiveProjectContext';
import { useActiveAnalysisIdUpdate } from '../../context/ActiveAnalysisContext';
const { Title } = Typography;
const { Content, Sider } = Layout;

export const urlValidationRules: Rule[] = [
    {
        required: true,
        message: 'Please enter URL',
    },
    {
        message: urlPolicyError,
        validator: (_, value) => {
            if (urlPolicy.test(value)) {
                return Promise.resolve();
            } else {
                return Promise.reject(urlPolicyError);
            }
        },
    },
];

const projectIdChangesEmitter = new BehaviorSubject<number | null>(null);
const projectIdChangesEvents$ = projectIdChangesEmitter.asObservable();

export const TrialPage: React.FC = () => {
    const [form] = Form.useForm();
    const [isAnalysing, setIsAnalysing] = useState<boolean>(false);
    const [projectId, setProjectId] = useState<number | null>(null);
    const [selectedTreeItem, setSelectedTreeItem] = useState<SiteMapItemProps | undefined>(undefined);
    const [projectsLoaded, setProjectsLoaded] = useState<boolean>(false);
    const [projects, setProjects] = useState<Project[]>([]);
    const [availableProjectsCount, setAvailableProjectsCount] = useState<number>(0);
    const [runAnimation, setRunAnimation] = useState<boolean>(false);
    const wsConnection = useWsConnection();
    const userSettings = useUserSettings();
    const setActiveProjectId = useActiveProjectIdUpdate();
    const setActiveAnalysisId = useActiveAnalysisIdUpdate();
    const [api, contextHolder] = notification.useNotification();

    const handleProjectChanges = useCallback(
        (updatedProject: ProjectWithPages) => {
            setProjectId(updatedProject.id);

            if (!updatedProject.pages.length) {
                return;
            }
            const selectedPage = updatedProject.pages[0];
            setSelectedTreeItem({ ...selectedPage, urlNode: selectedPage.url, splitUrl: [] });
            setTimeout(() => {
                form.resetFields(['url']);
            }, 10);
        },
        [setProjectId, form, setSelectedTreeItem],
    );

    const reset = useCallback(() => {
        setProjectId(null);
        setSelectedTreeItem(undefined);
    }, [setProjectId, setSelectedTreeItem]);

    const loadProject = useCallback(
        (id: number | null) => {
            if (!id) {
                return;
            }
            const controller = new AbortController();
            projectsService.getProjectById(id, controller.signal).then((loadedProject: ProjectWithPages) => {
                handleProjectChanges(loadedProject);
            });
            return () => {
                controller.abort();
            };
        },
        [handleProjectChanges],
    );

    const loadAllProjects = useCallback(() => {
        const controller = new AbortController();
        setProjectsLoaded(false);
        projectsService
            .getAllProjects(controller.signal)
            .then((projects) => {
                setProjects(projects);
                if (!projects.length) {
                    reset();
                    return;
                }
                loadProject(projects[0].id);
            })
            .finally(() => {
                setProjectsLoaded(true);
            });

        return () => {
            controller.abort();
        };
    }, [loadProject, setProjectsLoaded, setProjects, reset]);

    useEffect(() => {
        loadAllProjects();
    }, [loadAllProjects]);

    const submitUrlToAnalyse = useCallback(
        (formValue: { url: string }) => {
            if (!formValue.url) {
                return;
            }
            const animationDuration = 2000;
            let delayBeforeScreenUpdate = 0;

            if (projects.length === 0) {
                setRunAnimation(true);
                delayBeforeScreenUpdate = (animationDuration * 3) / 4;
            }

            const controller = new AbortController();
            setIsAnalysing(true);
            const analysisPayload = {
                url: formValue.url,
                siteObjectiveContext: '',
                companyContext: '',
                includeSubURLs: true,
                name: formValue.url,
                analysisTypes: [AnalysisType.Quality],
                promptConfiguration: null,
            };

            analyzerService
                .analyseSiteByUrl(analysisPayload, controller.signal)
                .then((createdProject: Project) => {
                    setTimeout(() => {
                        setProjects([createdProject, ...projects]);
                        handleProjectChanges({ ...createdProject, pages: [] });
                        loadProject(createdProject.id);
                    }, delayBeforeScreenUpdate);
                })
                .finally(() => {
                    setIsAnalysing(false);
                });

            return () => {
                controller.abort();
            };
        },
        [setIsAnalysing, handleProjectChanges, loadProject, setProjects, projects],
    );

    const handleProjectSelection = useCallback(
        (projectId: number) => {
            reset();
            loadProject(projectId);
        },
        [reset, loadProject],
    );

    const updateAvailableProjectsCount = useCallback(() => {
        const usedProjectsCount = projects.filter((x) => x.status.toString() !== 'Error').length;
        const limitOfProjects = userSettings?.limitOfProjects || 0;
        const availableProjectsCount = usedProjectsCount < limitOfProjects ? limitOfProjects - usedProjectsCount : 0;
        setAvailableProjectsCount(availableProjectsCount);
    }, [projects, userSettings, setAvailableProjectsCount]);

    const handleProjectDeleted = useCallback(
        (projectId: null | number) => {
            setProjectId(null);
            loadAllProjects();
        },
        [loadAllProjects],
    );

    const showLimitMessage = useCallback(
        (message: string) => {
            api.warning({
                message: 'Analysis reached limit',
                description: message,
                duration: 10,
                placement: 'top',
            });
        },
        [api],
    );

    useEffect(() => {
        if (!wsConnection) {
            return;
        }

        const analysisReachedLimitHandler = (event: { message: string; projectId: number }) => {
            if (event.projectId === projectId) {
                showLimitMessage(event.message);
            }
        };
        wsConnection.on('AnalysisReachedLimit', analysisReachedLimitHandler);

        const projectUpdatedHandler = (event: { message: string; project: Project }) => {
            let indexToUpdate = projects.findIndex((project) => project.id === event.project.id);
            projects[indexToUpdate] = { ...projects[indexToUpdate], ...event.project };
            // clone deep of the projects to trigger rendering of the sub components
            setProjects(JSON.parse(JSON.stringify(projects)));
            updateAvailableProjectsCount();
            if (event.project.id === projectId) {
                projectIdChangesEmitter.next(projectId);
            }
        };
        wsConnection.on('ProjectUpdated', projectUpdatedHandler);

        const projectCreatedHandler = (event: { message: string; project: Project }) => {
            setProjectId(event.project.id);
        };
        wsConnection.on('ProjectCreated', projectCreatedHandler);

        const analysisUpdatedHandler = (event: { message: string; analysis: Analysis }) => {
            if (event.analysis.page.projectId === projectId) {
                projectIdChangesEmitter.next(projectId);
            }
        };
        wsConnection.on('AnalysisEnded', analysisUpdatedHandler);

        return () => {
            wsConnection.off('ProjectCreated', projectCreatedHandler);
            wsConnection.off('ProjectUpdated', projectUpdatedHandler);
            wsConnection.off('AnalysisEnded', analysisUpdatedHandler);
            wsConnection.off('AnalysisReachedLimit', analysisReachedLimitHandler);
        };
    }, [wsConnection, projectId, loadProject, updateAvailableProjectsCount, projects, showLimitMessage]);

    useEffect(() => {
        const projectIdChangesEventsSubscription = projectIdChangesEvents$.pipe(debounceTime(500)).subscribe((id) => {
            if (!id || projectId !== id) {
                return;
            }
            loadProject(projectId);
        });

        return () => {
            projectIdChangesEventsSubscription.unsubscribe();
        };
    }, [loadProject, projectId]);

    useEffect(() => {
        updateAvailableProjectsCount();
    }, [updateAvailableProjectsCount]);

    useEffect(() => {
        setActiveProjectId(projectId);
        if (!projectId) {
            setActiveAnalysisId(null);
        }
        return () => {
            setActiveProjectId(null);
            setActiveAnalysisId(null);
        };
    }, [projectId, setActiveProjectId, setActiveAnalysisId]);

    return (
        <div className="trial-page">
            {contextHolder}
            {projectsLoaded && projects.length === 0 && (
                <Row align="middle" justify="center" style={{ height: '100%' }}>
                    <Col span={10}>
                        <div className={'site-name' + (runAnimation ? ' animated' : '')}>
                            <img width={64} height={64} alt="Door3 logo" src="/images/d3-logo.svg" />
                            <Title className="m-0" level={3}>
                                PerfectSite.ai
                            </Title>
                        </div>
                        <div style={{ textAlign: 'center', marginBottom: '1rem' }}>
                            <Title level={5}>Scan your first URL</Title>
                        </div>
                        <Form form={form} name="trial-analysis-form" onFinish={submitUrlToAnalyse} style={{ height: '2rem' }}>
                            <Space.Compact style={{ width: '100%' }}>
                                <Form.Item initialValue={selectedTreeItem?.url || ''} name="url" rules={urlValidationRules} style={{ width: '100%', margin: 0 }}>
                                    <Input disabled={!!projectId || !projectsLoaded} placeholder="Enter URL" />
                                </Form.Item>
                                <Button type="primary" htmlType="submit" disabled={isAnalysing || !!projectId || !projectsLoaded} loading={isAnalysing}>
                                    Analyze
                                </Button>
                            </Space.Compact>
                        </Form>
                    </Col>
                </Row>
            )}
            {projectsLoaded && projects.length > 0 && (
                <Content style={{ padding: '0 3rem' }}>
                    <Layout>
                        <Sider theme="light" width={'22%'} className="sider-menu">
                            <ProjectsMenu
                                projects={projects}
                                activeProjectId={projectId}
                                onActiveProjectIdChanged={handleProjectSelection}
                                onProjectDeleted={handleProjectDeleted}
                                showAddNewProject={false}
                                menuTitle={'Previous analyses'}
                            />
                        </Sider>
                        <Content className="project-content">
                            <div className="analyse-more">
                                <Flex gap={12} align="center">
                                    <div style={{ width: '6rem' }}>
                                        <b>Analyze More</b>
                                    </div>
                                    <div style={{ width: 'calc(100% - 16.5rem)' }}>
                                        <Form form={form} name="trial-analysis-form" onFinish={submitUrlToAnalyse} style={{ height: '2rem' }}>
                                            <Space.Compact style={{ width: '100%' }}>
                                                <Form.Item name="url" rules={urlValidationRules} style={{ width: '100%', margin: 0 }}>
                                                    <Input disabled={!projectsLoaded || availableProjectsCount < 1} placeholder="+ Enter URL" />
                                                </Form.Item>
                                                <Button
                                                    style={{ margin: 0 }}
                                                    type="primary"
                                                    htmlType="submit"
                                                    disabled={isAnalysing || !projectsLoaded || availableProjectsCount < 1}
                                                    loading={isAnalysing}
                                                >
                                                    Analyze
                                                </Button>
                                            </Space.Compact>
                                        </Form>
                                    </div>
                                    <div style={{ width: '9rem' }}>
                                        {availableProjectsCount} Free {availableProjectsCount === 1 ? 'analysis' : 'analyses'} left
                                    </div>
                                </Flex>
                            </div>
                            {!projectId && (
                                <Spin tip="Loading" size="small" style={{ marginTop: '4rem' }}>
                                    <div className="content" />
                                </Spin>
                            )}
                            {projectId && selectedTreeItem && <TrialPageDetails page={selectedTreeItem} />}
                        </Content>
                    </Layout>
                </Content>
            )}
        </div>
    );
};
