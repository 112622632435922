import { apiService, ApiService } from '../api/apiService';
import { Project } from '../projects/dto/project';
import { Analysis } from '../pages/dto/page';
import { ScanDto } from './dto/scan.dto';
import { AnalysisFeedbackDto } from './dto/analysis-feedback.dto';

export class AnalyzerService {
    constructor(private apiService: ApiService) {}

    addFeedback(dto: AnalysisFeedbackDto, signal?: AbortSignal): Promise<AnalysisFeedbackDto> {
        return this.apiService.post('analysis/add-feedback', { body: dto, signal });
    }

    analyseSiteByUrl(dto: ScanDto, signal?: AbortSignal): Promise<Project> {
        return this.apiService.post('analyzer/analyse-site-by-url', { body: dto, signal });
    }

    startAnalysesByPageId(id: number, analyseSubPages: boolean = false, analysisTypes: string[] = [], pageContext: string): Promise<Analysis[]> {
        return this.apiService.post('analyzer/analyse-site-page', {
            body: {
                pageId: id,
                includeSubURLs: analyseSubPages,
                analysisTypes,
                context: pageContext,
            },
        });
    }
}

export const analyzerService = new AnalyzerService(apiService);
