import React, { useCallback, useState } from 'react';
import './Feedback.scss';

import { MessageFilled, SendOutlined } from '@ant-design/icons';
import { Alert, Button, ButtonProps, Form, Input, message, Modal, Rate } from 'antd';
import { feedbacksService } from '../../services/feedbacks/feedbacksService';
import { useActiveProjectId } from '../../context/ActiveProjectContext';
import { useActiveAnalysisId } from '../../context/ActiveAnalysisContext';
const { TextArea } = Input;

const sendButtonProps: ButtonProps = {
    type: 'primary',
    icon: <SendOutlined />,
};

const feedbackFormInitialValues = { score: 5, message: '' };
const feedbackSentMessage = 'Thank you for taking the time to provide us with your feedback. Your input is extremely valuable and helps us to improve our services.';

export const Feedback: React.FC = () => {
    const [form] = Form.useForm();
    const projectId: number | null = useActiveProjectId();
    const analysisId: number | null = useActiveAnalysisId();
    const [messageApi, contextHolder] = message.useMessage();
    const [feedbackModalIsOpen, setFeedbackModalIsOpen] = useState<boolean>(false);
    const [feedbackSubmitting, setFeedbackSubmitting] = useState<boolean>(false);
    const [feedbackSent, setFeedbackSent] = useState<boolean>(false);

    const onFeedbackSubmit = useCallback(() => {
        setFeedbackSubmitting(true);
        const feedbackPayload = form.getFieldsValue(true);
        feedbacksService
            .sendFeedback({ ...feedbackPayload, projectId, analysisId })
            .then(() => {
                form.resetFields();
                setFeedbackSent(true);
            })
            .catch((error) => {
                messageApi.open({
                    type: 'error',
                    content: error?.message || 'Oops. Something went wrong. Please try again later.',
                    duration: 3,
                });
            })
            .finally(() => {
                setFeedbackSubmitting(false);
            });
    }, [form, setFeedbackSubmitting, messageApi, projectId, analysisId]);

    const closeFeedbackForm = useCallback(() => {
        setFeedbackModalIsOpen(false);
        setFeedbackSent(false);
    }, [setFeedbackModalIsOpen, setFeedbackSent]);

    return (
        <>
            {contextHolder}
            <Button className="feedback-btn" type="primary" icon={<MessageFilled />} onClick={() => setFeedbackModalIsOpen(true)}>
                Feedback
            </Button>
            <Modal
                title={feedbackSent ? 'Feedback sent' : 'Tell us how you like the app'}
                style={{ margin: '-7rem 3rem 0 0', top: '50%', float: 'right' }}
                width={400}
                open={feedbackModalIsOpen}
                okButtonProps={sendButtonProps}
                okText={'Send'}
                cancelText={'Close'}
                onOk={onFeedbackSubmit}
                onCancel={closeFeedbackForm}
                confirmLoading={feedbackSubmitting}
                footer={(_, { OkBtn, CancelBtn }) => (
                    <>
                        {!feedbackSent && <OkBtn />}
                        {feedbackSent && <CancelBtn />}
                    </>
                )}
            >
                {feedbackSent && <Alert showIcon description={feedbackSentMessage} type="success" />}
                {!feedbackSent && (
                    <Form layout="vertical" name="feedback_form" form={form} initialValues={feedbackFormInitialValues}>
                        <Form.Item
                            name="score"
                            label="How would you rate your experience?"
                            rules={[{ required: true, message: 'Please rate your experience!' }]}
                            style={{ marginBottom: '0.5rem' }}
                        >
                            <Rate allowClear={false} />
                        </Form.Item>
                        <Form.Item name="message" label="Tell us more about your experience:">
                            <TextArea rows={4} placeholder="Leave your feedback here" />
                        </Form.Item>
                    </Form>
                )}
            </Modal>
        </>
    );
};
