import React, { useCallback, useEffect, useState } from 'react';
import { PromptConfigurationDto } from '../../services/settings/dto/prompt-configuration.dto';
import { settingsService } from '../../services/settings/settingsService';
import { EditPromptConfigurationButton } from '../EditPromptConfigurationButton/EditPromptConfigurationButton';
import { useAppSettings, useAppSettingsUpdate } from '../../context/AppSettingsContext';
import { PermissionName, usePermissions } from '../../hooks/usePermissions';

export const EditGlobalPromptConfig: React.FC = () => {
    const [promptConfig, setPromptConfig] = useState<PromptConfigurationDto | null>(null);
    const appSettings = useAppSettings();
    const setAppSettings = useAppSettingsUpdate();
    const { hasPermission } = usePermissions();

    useEffect(() => {
        setPromptConfig(appSettings ? appSettings.promptConfiguration : null);
    }, [setPromptConfig, appSettings]);

    const handlePromptConfigUpdate = useCallback(
        (updatedPromptConfig: PromptConfigurationDto) => {
            setPromptConfig(updatedPromptConfig);

            settingsService.savePromptConfiguration(updatedPromptConfig).then((updatedGlobalSettings) => {
                setPromptConfig(updatedGlobalSettings.promptConfiguration);
                setAppSettings(updatedGlobalSettings);
            });
        },
        [setPromptConfig, setAppSettings],
    );

    return (
        <>
            {promptConfig && hasPermission(PermissionName.GlobalPromptConfigEdit) && (
                <EditPromptConfigurationButton
                    editPromptConfigButtonText="Update Global Prompts"
                    initialPromptConfig={promptConfig}
                    onPromptConfigUpdated={handlePromptConfigUpdate}
                />
            )}
        </>
    );
};
