import React from 'react';
import { Flex, Typography } from 'antd';

const { Text } = Typography;

export const AuthFormHeader: React.FC<{ hideText?: boolean | undefined }> = ({ hideText }) => {
    return (
        <Flex align={'center'} vertical>
            <img style={{ filter: 'invert(100%)', marginBottom: hideText ? '1rem' : '0' }} width={56} height={56} alt="Door3 logo" src="/images/d3-logo.svg" />
            {!hideText && <Text style={{ padding: '1rem 0 2rem' }}>Join the Perfect Site</Text>}
        </Flex>
    );
};
