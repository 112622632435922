import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { useAuth } from './AuthContext';
import jwt_decode from 'jwt-decode';
import { DecodedToken } from '../services/api/apiService';

export enum UserRole {
    FreeTrialUser = 'FreeTrialUser',
    PaidCustomer = 'PaidCustomer',
    Admin = 'Admin',
}

export interface User {
    id: number;
    email: string;
    role: UserRole;
}

export const UserContext = React.createContext<User | null>(null);

export const useUser = () => {
    return useContext<User | null>(UserContext);
};

export const UserProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const authTokens = useAuth();

    const [userState, setUserState] = useState<User | null>(null);

    useEffect(() => {
        if (!authTokens?.access_token) {
            return;
        }

        const { sub, role, username }: DecodedToken = jwt_decode(authTokens.access_token);
        setUserState({
            id: sub,
            role,
            email: username,
        });
    }, [authTokens]);

    return <UserContext.Provider value={userState}>{children}</UserContext.Provider>;
};
