import React, { useCallback, useEffect, useState } from 'react';
import { Badge, Button, Card, Col, message, Progress, Result, Row, Spin, Switch } from 'antd';
import { SiteMapTree } from '../SiteMapTree/SiteMapTree';
import { projectsService } from '../../services/projects/projectsService';
import { Analysis, Page, PageStatus } from '../../services/pages/dto/page';
import { SiteMapItemProps } from '../SiteMapTree/models/sitemap-item-props';
import { PageDetails } from '../PageDetails/PageDetails';
import { useWsConnection } from '../../context/WsConnectionContext';
import { Project, ProjectStatus, ProjectWithPages } from '../../services/projects/dto/project';
import './ProjectDetails.scss';
import { BehaviorSubject, debounceTime } from 'rxjs';
import TextArea from 'antd/es/input/TextArea';
import { contextMaxLength } from '../AddNewProjectForm/AddNewProjectForm';
import { PermissionName, usePermissions } from '../../hooks/usePermissions';

const SitemapDefaultValue: Page[] = [];

const projectIdChangesEmitter = new BehaviorSubject<number | null>(null);
const projectIdChangesEvents$ = projectIdChangesEmitter.asObservable();

export const ProjectDetails: React.FC<{ id: number }> = ({ id }) => {
    const [sitemap, setSitemap] = useState<Page[]>(SitemapDefaultValue);
    const [project, setProject] = useState<ProjectWithPages | null>(null);
    const [projectStatus, setProjectStatus] = useState<ProjectStatus | null>(null);
    const [selectedTreeItem, setSelectedTreeItem] = useState<SiteMapItemProps | undefined>(undefined);
    const [messageApi, contextHolder] = message.useMessage({
        maxCount: 7,
        duration: 0.5,
    });
    const wsConnection = useWsConnection();
    const [percentCrawledPages, setPercentCrawledPages] = useState<number>(0);
    const [percentAnalysedPages, setPercentAnalysedPages] = useState<number>(0);
    const [projectChangedSinceLastUpdate, setProjectChangedSinceLastUpdate] = useState<boolean>(false);
    const [autoUpdateProject, setAutoUpdateProject] = useState<boolean>(true);
    const [siteObjectiveContext, setSiteObjectiveContext] = useState<string>('');
    const [companyContext, setCompanyContext] = useState<string>('');
    const { hasPermission } = usePermissions();

    const loadProject = useCallback(
        (projectId: number) => {
            if (!projectId || projectId !== id) {
                return;
            }
            setProjectChangedSinceLastUpdate(false);
            projectsService.getProjectById(projectId).then((projectWithPages) => {
                setProject(projectWithPages);
                setSiteObjectiveContext(projectWithPages.siteObjectiveContext);
                setCompanyContext(projectWithPages.companyContext);
                setProjectStatus(projectWithPages.status);
                setSitemap(projectWithPages.pages);
                const pagesCrawledCount = projectWithPages.pages.filter(({ status }) => status !== PageStatus.Draft && status !== PageStatus.Crawling).length;
                const pagesAnalysedCount = projectWithPages.pages.filter(({ status }) => status === PageStatus.Analysed).length;
                const allPagesCount = projectWithPages.pages.length;
                setPercentCrawledPages(Math.round((pagesCrawledCount * 100) / allPagesCount));
                setPercentAnalysedPages(Math.round((pagesAnalysedCount * 100) / allPagesCount));
            });
        },
        [setSitemap, setProjectStatus, setPercentCrawledPages, setSiteObjectiveContext, setCompanyContext, setProject, id],
    );

    useEffect(() => {
        const projectIdChangesEventsSubscription = projectIdChangesEvents$.pipe(debounceTime(500)).subscribe((projectId) => {
            if (!projectId || projectId !== id) {
                return;
            }
            setProjectChangedSinceLastUpdate(true);
            if (autoUpdateProject) {
                loadProject(projectId);
            }
        });

        return () => {
            projectIdChangesEventsSubscription.unsubscribe();
        };
    }, [loadProject, autoUpdateProject, id]);

    useEffect(() => {
        if (!wsConnection) {
            return;
        }

        const projectUpdatedHandler = (event: { message: string; project: Project }) => {
            if (event.project.id === id) {
                projectIdChangesEmitter.next(id);
            }
        };
        wsConnection.on('ProjectUpdated', projectUpdatedHandler);

        const projectNotificationHandler = (event: { message: string; projectId: number }) => {
            if (event.projectId === id) {
                messageApi.info(event.message);
            }
        };
        wsConnection.on('ProjectNotification', projectNotificationHandler);

        const analysisUpdatedHandler = (event: { message: string; analysis: Analysis }) => {
            if (event.analysis.page.projectId === id) {
                projectIdChangesEmitter.next(id);
            }
        };
        wsConnection.on('AnalysisEnded', analysisUpdatedHandler);

        return () => {
            wsConnection.off('ProjectUpdated', projectUpdatedHandler);
            wsConnection.off('ProjectNotification', projectNotificationHandler);
            wsConnection.off('AnalysisEnded', analysisUpdatedHandler);
        };
    }, [wsConnection, id, loadProject, messageApi]);

    useEffect(() => {
        setProjectStatus(null);
        setSelectedTreeItem(undefined);
        setProjectChangedSinceLastUpdate(false);
        loadProject(id);
        // projectIdChangesEmitter.next(id);
    }, [id, loadProject]);

    const handleTreeItemSelection = useCallback((treeItem: any) => {
        setSelectedTreeItem(treeItem);
    }, []);

    const handleUpdateProjectClick = useCallback(() => {
        loadProject(id);
    }, [loadProject, id]);

    const handleAutoUpdateToggle = useCallback(
        (checked: boolean) => {
            setAutoUpdateProject(checked);
            if (checked) {
                projectIdChangesEmitter.next(id);
            }
        },
        [setAutoUpdateProject, id],
    );

    const [resultsAreLoading, setResultsAreLoading] = useState<boolean>(false);

    const handleProjectDetailsSubmit = useCallback(() => {
        setResultsAreLoading(true);
        projectsService
            .updateProject({
                ...project!,
                siteObjectiveContext: siteObjectiveContext!,
                companyContext: companyContext!,
                pages: [],
            })
            .then((updatedProject: ProjectWithPages) => {
                setProject(updatedProject);
                loadProject(updatedProject.id!);
            })
            .finally(() => {
                setResultsAreLoading(false);
            });
    }, [setProject, setResultsAreLoading, siteObjectiveContext, companyContext, project, loadProject]);

    const onCompanyContextChange = useCallback(
        (e: any) => {
            setCompanyContext(e.target.value);
        },
        [setCompanyContext],
    );

    const onSiteContextChange = useCallback(
        (e: any) => {
            setSiteObjectiveContext(e.target.value);
        },
        [setSiteObjectiveContext],
    );

    return (
        <div>
            <Card className="ProjectShorDescription">
                <Row gutter={16}>
                    <Col span={8}>
                        <p>Project status: {projectStatus}</p>
                        <p>Pages count: {sitemap.length}</p>
                        <Row>
                            <Col span={4}>Crawled</Col>
                            <Col span={20}>
                                <Progress percent={percentCrawledPages} size="small" />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={4}>Analysed</Col>
                            <Col span={20}>
                                <Progress percent={percentAnalysedPages} size="small" />
                            </Col>
                        </Row>
                    </Col>
                    <Col span={16}>
                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                <Switch onChange={handleAutoUpdateToggle} checked={autoUpdateProject} /> Auto update Project on changes
                            </Col>
                            <Col span={12}>
                                {projectChangedSinceLastUpdate && (
                                    <div>
                                        <Badge status="processing" /> Project has changed.
                                        <Button type="link" onClick={handleUpdateProjectClick}>
                                            Click to update.
                                        </Button>
                                    </div>
                                )}
                            </Col>

                            <Col span={12}>
                                {hasPermission(PermissionName.ContextEdit) && 
                                    <div>
                                        Context:
                                        <TextArea
                                            showCount
                                            maxLength={contextMaxLength}
                                            value={companyContext}
                                            onChange={onCompanyContextChange}
                                            autoSize={{ minRows: 2, maxRows: 4 }}
                                            placeholder="Please describe the company that this site serves"
                                        />
                                    </div>
                                }
                            </Col>
                            <Col span={12}>
                            {hasPermission(PermissionName.ContextEdit) && 
                                <div>
                                    Site Objective:
                                    <TextArea
                                        showCount
                                        maxLength={contextMaxLength}
                                        value={siteObjectiveContext}
                                        onChange={onSiteContextChange}
                                        autoSize={{ minRows: 2, maxRows: 4 }}
                                        placeholder="Please describe the key objective that the site is intended to achieve for the company."
                                    />
                                </div>
                            }
                            </Col>
                            <Col span={24}>
                            {hasPermission(PermissionName.ContextEdit) && 
                                <Button type="primary" onClick={handleProjectDetailsSubmit} loading={resultsAreLoading}>
                                    Update
                                </Button>
                            }
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card>
            {contextHolder}
            {!projectStatus && (
                <div className="SpinWrapper">
                    <Spin tip="Loading" size="large">
                        <div className="content" />
                    </Spin>
                </div>
            )}
            {projectStatus === ProjectStatus.Created && <div>Project was created but not scanned yet</div>}
            {projectStatus === ProjectStatus.Scanned && sitemap.length === 0 && <Result status="error" title="Scanning Failed" subTitle="Please check URL or try later." />}
            {projectStatus && projectStatus !== ProjectStatus.Created && sitemap.length > 0 && (
                <Row gutter={16}>
                    <Col span={8}>
                        <Card className="pageScrollable">{sitemap && sitemap.length > 0 && <SiteMapTree sitemap={sitemap} onTreeItemSelect={handleTreeItemSelection} />}</Card>
                    </Col>
                    {selectedTreeItem && (
                        <Col span={16}>
                            <div>
                                <PageDetails page={selectedTreeItem} />
                            </div>
                        </Col>
                    )}
                </Row>
            )}
        </div>
    );
};
