import React, { useCallback, useEffect, useState } from 'react';
import { Badge, Space, Tooltip } from 'antd';
import { SiteMapItemProps } from './models/sitemap-item-props';
import { CheckCircleOutlined, LoadingOutlined, StopOutlined, SyncOutlined, FileOutlined } from '@ant-design/icons';
import { Page, PageStatus } from '../../services/pages/dto/page';
import { useWsConnection } from '../../context/WsConnectionContext';
import { Color } from '../Shared/color.enum';
import { HttpResponseTypeColors, getHttpResponseType } from '../Shared/http-status-type';

export const SiteMapItem: React.FC<{ itemProps: SiteMapItemProps }> = ({ itemProps }) => {
    const wsConnection = useWsConnection();
    const [pageStatus, setPageStatus] = useState<PageStatus>(PageStatus.Draft);
    const [urlStatusCode, setUrlStatusCode] = useState<number>(0);
    const [urlStatusMessage, setUrlStatusMessage] = useState<string>('');
    const [badgeColor, setBadgeColor] = useState(Color.White);

    const updateItem = useCallback((updatedPage: Page) => {
        const httpResponseType = getHttpResponseType(itemProps.urlStatusCode);
        setBadgeColor(HttpResponseTypeColors[httpResponseType] || Color.White);
        setPageStatus(updatedPage.status);
        setUrlStatusCode(updatedPage.urlStatusCode);
        setUrlStatusMessage(updatedPage.urlStatusMessage);
    }, [setBadgeColor, setPageStatus, setUrlStatusCode, setUrlStatusMessage, itemProps.urlStatusCode]);

    useEffect(() => {
        updateItem(itemProps as Page);
    }, [itemProps, updateItem]);

    useEffect(() => {
        if (!wsConnection) {
            return;
        }

        const pageUpdatedHandler = (event: { message: string; page: Page }) => {
            if (event.page.id !== itemProps.id) {
                return;
            }

            updateItem(event.page);
        };

        wsConnection.on('PageUpdated', pageUpdatedHandler);

        return () => {
            wsConnection.off('PageUpdated', pageUpdatedHandler);
        };
    }, [wsConnection, itemProps.id, updateItem]);

    return (
        <div>
            <Space size="small">
                <Tooltip placement="top" title={urlStatusCode === 200 ? '' : urlStatusMessage} zIndex={9999}>
                    <Badge size="small" count={urlStatusCode !== 200 ? urlStatusCode : 0} overflowCount={999} title='' color={badgeColor}>
                        {(() => {
                            switch (pageStatus) {
                                case PageStatus.Error:
                                    return <StopOutlined style={{ color: '#C51605' }} />;
                                case PageStatus.Crawling:
                                    return <SyncOutlined spin={true} />;
                                case PageStatus.Crawled:
                                    return <FileOutlined />;
                                case PageStatus.Analysing:
                                    return <LoadingOutlined />;
                                case PageStatus.Analysed:
                                    return <CheckCircleOutlined style={{ color: '#52c41a' }} />;
                                default:
                                    return null;
                            }
                        })()}
                    </Badge>
                </Tooltip>
                <span>{itemProps.urlNode}</span>
            </Space>
        </div>
    );
};
