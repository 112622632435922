import React, { ReactNode, useContext, useState } from 'react';

export const ActiveAnalysisContext = React.createContext<number | null>(null);
export const ActiveAnalysisUpdateContext = React.createContext<Function>(() => {});

export const useActiveAnalysisId = () => {
    return useContext<number | null>(ActiveAnalysisContext);
};

export const useActiveAnalysisIdUpdate = () => {
    return useContext<Function>(ActiveAnalysisUpdateContext);
};

export const ActiveAnalysisProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [analysisId, setAnalysisId] = useState<number | null>(null);
    return (
        <ActiveAnalysisContext.Provider value={analysisId}>
            <ActiveAnalysisUpdateContext.Provider value={setAnalysisId}>{children}</ActiveAnalysisUpdateContext.Provider>
        </ActiveAnalysisContext.Provider>
    );
};
