import React, { useCallback, useEffect, useState } from 'react';
import { SiteMapItemProps } from '../../components/SiteMapTree/models/sitemap-item-props';
import { pagesService } from '../../services/pages/pagesService';
import { Analysis, Page, PageStatus } from '../../services/pages/dto/page';
import { Spin, Steps, Typography } from 'antd';
import { LinkOutlined } from '@ant-design/icons';
import './TrialPageDetails.scss';
import { useWsConnection } from '../../context/WsConnectionContext';
import { renderAnalysisResult } from '../../components/Analyses/RenderAnalyses';
import { Project } from '../../services/projects/dto/project';
import { BehaviorSubject, debounceTime } from 'rxjs';
import { useActiveAnalysisIdUpdate } from '../../context/ActiveAnalysisContext';

const { Text } = Typography;

const analysisSteps = [
    {
        title: 'Create',
        pageStatus: PageStatus.Draft,
    },
    {
        title: 'Crawling',
        pageStatus: PageStatus.Crawling,
    },
    {
        title: 'Crawled',
        pageStatus: PageStatus.Crawled,
    },
    {
        title: 'Analysing',
        pageStatus: PageStatus.Analysing,
    },
    {
        title: 'Analysed',
        pageStatus: PageStatus.Analysed,
    },
];

const stepsItemsProp = analysisSteps.map(({ title }) => ({ title }));

const pageChangesEmitter = new BehaviorSubject<any>(null);
const pageChangesEvents$ = pageChangesEmitter.asObservable();
const imageSize = 400;

export const TrialPageDetails: React.FC<{ page: SiteMapItemProps }> = ({ page }) => {
    const [loadedPageId, setLoadedPageId] = useState<number | null>(null);
    const [analyses, setAnalyses] = useState<Analysis[]>([]);
    const [pageText, setPageText] = useState(page.text);
    const [pageStatus, setPageStatus] = useState<PageStatus>(page.status || PageStatus.Draft);
    const [urlStatusCode, setUrlStatusCode] = useState<number>(page.urlStatusCode);
    const [urlStatusMessage, setUrlStatusMessage] = useState<string>(page.urlStatusMessage);
    const [activeAnalysisStep, setActiveAnalysisStep] = useState<number>(0);
    const wsConnection = useWsConnection();
    const setActiveAnalysisId = useActiveAnalysisIdUpdate();

    const loadPage = useCallback(() => {
        const controller = new AbortController();
        pagesService.getPageById(page.id, controller.signal).then((pageWithAnalyses) => {
            setLoadedPageId(page.id);
            setAnalyses(pageWithAnalyses?.analyses || []);
            setPageText(pageWithAnalyses.text);
            setPageStatus(pageWithAnalyses.status);
            setUrlStatusCode(pageWithAnalyses.urlStatusCode);
            setUrlStatusMessage(pageWithAnalyses.urlStatusMessage);
            const stepIndex = analysisSteps.findIndex((step) => step.pageStatus === pageWithAnalyses.status) || 0;
            setActiveAnalysisStep(stepIndex);
        });
        return () => {
            controller.abort();
        };
    }, [page, setLoadedPageId, setAnalyses, setPageText, setPageStatus, setActiveAnalysisStep, setUrlStatusCode, setUrlStatusMessage]);

    useEffect(() => {
        if (loadedPageId === page.id) {
            return;
        }
        pageChangesEmitter.next(null);
    }, [page, loadPage, loadedPageId]);

    useEffect(() => {
        if (!wsConnection) {
            return;
        }

        const pageUpdatedHandler = (event: { message: string; page: Page }) => {
            if (event.page.id === page.id) {
                pageChangesEmitter.next(null);
            }
        };
        wsConnection.on('PageUpdated', pageUpdatedHandler);

        const projectUpdatedHandler = (event: { message: string; project: Project }) => {
            if (event.project.id === page.projectId) {
                pageChangesEmitter.next(null);
            }
        };

        wsConnection.on('ProjectUpdated', projectUpdatedHandler);

        return () => {
            wsConnection.off('PageUpdated', pageUpdatedHandler);
            wsConnection.off('ProjectUpdated', pageUpdatedHandler);
        };
    }, [wsConnection, page.id, loadPage, page.projectId]);

    useEffect(() => {
        const pageChangesEventsSubscription = pageChangesEvents$.pipe(debounceTime(100)).subscribe((_) => {
            loadPage();
        });

        return () => {
            pageChangesEventsSubscription.unsubscribe();
        };
    }, [loadPage]);

    useEffect(() => {
        setActiveAnalysisId((analyses.length && analyses[0] && analyses[0].id) || null);
    }, [analyses, setActiveAnalysisId]);

    return (
        <div>
            {!!pageStatus && (
                <div style={{ marginBottom: '1rem' }}>
                    {pageStatus === PageStatus.Error ? (
                        <>
                            <div className={'error-analyzing-header'}>
                                <Text type="warning" strong>
                                    Error while analysing this page.
                                </Text>
                            </div>
                        </>
                    ) : (
                        <Steps size="small" current={activeAnalysisStep} items={stepsItemsProp}></Steps>
                    )}
                </div>
            )}

            <div className="quality-analysis-details">
                <Text className="page-title" ellipsis={true}>
                    <a style={{ marginRight: '0.5rem' }} href={page.url} rel="noreferrer" target="_blank">
                        <LinkOutlined />
                    </a>
                    <b>{page.title || page.url || page.project?.url}</b>
                </Text>

                <div className="analysedPageDetails">
                    {(() => {
                        switch (pageStatus) {
                            case PageStatus.Draft:
                                return (
                                    <div className="analysis-status-details">
                                        <p>This may take up to 5 minutes. Please wait.</p>
                                        <img width={imageSize} height={imageSize} alt="Pending" src="/images/chatbot-pending.gif" />
                                    </div>
                                );
                            case PageStatus.Crawling:
                                return (
                                    <div className="analysis-status-details">
                                        <p>This may take up to 5 minutes. Please wait.</p>
                                        <img width={imageSize} height={imageSize} alt="Crawling" src="/images/chatbot-crawling.gif" />
                                    </div>
                                );
                            case PageStatus.Crawled:
                                return (
                                    <div className="analysis-status-details">
                                        {urlStatusCode !== 200 && (
                                            <p>
                                                Response status message: <b>{urlStatusMessage}</b>.
                                            </p>
                                        )}
                                        {urlStatusCode === 200 && <div style={{ whiteSpace: 'pre-line' }}>{pageText}</div>}
                                        {urlStatusCode !== 200 && <img width={imageSize} height={imageSize} alt="Error" src="/images/chatbot-error.gif" />}
                                    </div>
                                );
                            case PageStatus.Analysing:
                                return (
                                    <div className="analysis-status-details">
                                        <p>This may take up to 5 minutes. Please wait.</p>
                                        <img width={imageSize} height={imageSize} alt="Analysing" src="/images/chatbot-analysing.gif" />
                                    </div>
                                );
                            case PageStatus.Analysed:
                                return analyses.length > 0 ? (
                                    renderAnalysisResult(analyses[0], pageText, 'scrollable-content', setAnalyses)
                                ) : (
                                    <Spin tip="Loading" size="small" style={{ marginTop: '4rem' }}>
                                        <div className="content" />
                                    </Spin>
                                );
                            case PageStatus.Error:
                            default:
                                return (
                                    <div className="analysis-status-details">
                                        {(urlStatusCode || urlStatusMessage) && (
                                            <p>
                                                Url respond with status code <b>{urlStatusCode}</b>: {urlStatusMessage}
                                            </p>
                                        )}
                                        <div style={{ whiteSpace: 'pre-line', color: '#777' }}>{pageText}</div>
                                        <img width={imageSize} height={imageSize} alt="Error" src="/images/chatbot-error.gif" />
                                    </div>
                                );
                        }
                    })()}
                </div>
            </div>
        </div>
    );
};
