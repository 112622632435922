import React, { useCallback } from 'react';

import { useLinkedIn } from 'react-linkedin-login-oauth2';
import { useAuthUpdate } from '../../../context/AuthContext';
import { Button, message } from 'antd';
import { authService } from '../../../services/auth/auth.service';
import { linkedInAuthClientId } from '../../../App.config';
import { AuthToken } from '../../../services/auth/dto/auth-token.dto';

export const LinkedInPage: React.FC = () => {
    const setUserToken = useAuthUpdate();
    const [messageApi, contextHolder] = message.useMessage();

    const handleLinkedInAuth = useCallback(
        (tokens: AuthToken) => {
            if (tokens) {
                messageApi.success('Login successful');
                setUserToken(tokens);
            } else {
                messageApi.error('error');
            }
        },
        [setUserToken, messageApi],
    );

    const handleSuccess = (code: string) => {
        if (!code) {
            return;
        }

        authService
            .loginWithLinkedIn(code)
            .then((res) => {
                handleLinkedInAuth(res);
            })
            .catch((e) => {
                messageApi.error(e.message);
            });
    };

    const handleError = () => {
        messageApi.error('Login Failed');
    };

    const { linkedInLogin } = useLinkedIn({
        clientId: linkedInAuthClientId,
        redirectUri: `${window.location.origin}/linkedin`,
        onSuccess: (code) => {
            handleSuccess(code);
        },
        onError: (error) => {
            if (error.error !== 'user_closed_popup') {
                handleError();
            }
        },
        scope: 'openid,email',
    });

    return (
        <>
            {contextHolder}
            <Button shape="circle" size={'large'} onClick={linkedInLogin} style={{ background: 'white', lineHeight: '2rem' }}>
                <img width={20} height={20} alt="Linkedin logo" src="/images/linkedin.svg" />
            </Button>
        </>
    );
};
