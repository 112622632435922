import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { localStorageService } from '../services/local-storage/local-storage.service';
import { AuthToken } from '../services/auth/dto/auth-token.dto';

export const AuthContext = React.createContext<AuthToken>({ access_token: null, refresh_token: null });
export const AuthUpdateContext = React.createContext<Function>(() => {});

export const useAuth = () => {
    return useContext<AuthToken>(AuthContext);
};

export const useAuthUpdate = () => {
    return useContext<Function>(AuthUpdateContext);
};

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const authTokens = localStorageService.getAuthToken();

    const [authState, setAuthState] = useState<AuthToken>({
        access_token: authTokens == null ? null : authTokens.access_token,
        refresh_token: authTokens == null ? null : authTokens.refresh_token,
    });

    useEffect(() => {
        const handleStorageChanges = () => {
            const storageTokens = localStorageService.getAuthToken();
            if (storageTokens?.access_token !== authState.access_token && storageTokens) {
                setAuthState(storageTokens);
            }
        };

        window.addEventListener('localStorage', handleStorageChanges, false);
        return () => {
            window.removeEventListener('localStorage', handleStorageChanges, false);
        };
    }, [authState, setAuthState]);

    const setAuthStateExtended = (state: AuthToken) => {
        setAuthState(state);
        if (state.access_token) {
            localStorageService.setAuthToken(state);
        } else {
            localStorageService.clearAuthToken();
        }
    };
    return (
        <AuthContext.Provider value={authState}>
            <AuthUpdateContext.Provider value={setAuthStateExtended}>{children}</AuthUpdateContext.Provider>
        </AuthContext.Provider>
    );
};
