import { apiService, ApiService, RequestOptions } from '../api/apiService';
import { GlobalAppSettingsDto } from './dto/global-app-settings.dto';
import { PromptConfigurationDto } from './dto/prompt-configuration.dto';
import { UserSettingsDto } from './dto/user-settings.dto';

export class SettingsService {
    constructor(private apiService: ApiService) {}

    getGlobalAppSettings(options: RequestOptions<any> = {}): Promise<GlobalAppSettingsDto> {
        return this.apiService.get('settings/app', options);
    }

    getUserSettings(options: RequestOptions<any> = {}): Promise<UserSettingsDto> {
        return this.apiService.get('settings/user', options);
    }

    savePromptConfiguration(updatedPrompt: PromptConfigurationDto): Promise<GlobalAppSettingsDto> {
        return this.apiService.post('settings/prompt-configuration', { body: updatedPrompt });
    }
}

export const settingsService = new SettingsService(apiService);
