import { apiService, ApiService } from '../api/apiService';

export class UsersService {
    constructor(private apiService: ApiService) {}

    getAvailableTokens(): Promise<number>{
        return this.apiService.get('users/available-tokens');
    }
}

export const usersService = new UsersService(apiService);
