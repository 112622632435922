import { apiService, ApiService } from '../api/apiService';
import { Lookups } from './dto/lookup.dto';

export class LookupsService {
    constructor(private apiService: ApiService) {}

    getAll(): Promise<Lookups> {
        return this.apiService.get('lookups');
    }
}

export const lookupsService = new LookupsService(apiService);
