import React, { useCallback, useEffect, useState } from 'react';
import { statisticService } from '../../../services/statistic/statisticService';
import { ConfigProvider, DatePicker, Flex, Layout, Space, Table } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { lightThemeConfig } from '../../../components/Layouts/themeConfigs';
import { ColumnsType } from 'antd/es/table';
import { Analysis } from '../../../services/pages/dto/page';

const { RangePicker } = DatePicker;
type RangeValue = [Dayjs | null, Dayjs | null] | null;

const tokensFormat = (num: number): string => {
    return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

const analysisInputTotalCost = (analysis: Analysis | undefined): number => {
    return (analysis?.usedOpenAITokensToInputLimitedAnalysisContent || 0) + (analysis?.usedOpenAITokensToInputAnalysisPrompt || 0);
};

const analysisOutputTotalCost = (analysis: Analysis | undefined): number => {
    return analysis?.usedOpenAITokensToOutputAnalysisResults || 0;
};

const columns: ColumnsType<Analysis> = [
    { title: 'Id', dataIndex: 'id', key: 'id' },
    { title: 'createdAt', dataIndex: 'createdAt', key: 'createdAt' },
    { title: 'Initial Words', dataIndex: 'pageWordsCount', key: 'pageWordsCount' },
    { title: 'Analyzed Words', dataIndex: 'analyzedWordsCount', key: 'analyzedWordsCount' },
    { title: 'Input content', dataIndex: 'usedOpenAITokensToInputLimitedAnalysisContent', key: 'usedOpenAITokensToInputLimitedAnalysisContent' },
    { title: 'Input prompt', dataIndex: 'usedOpenAITokensToInputAnalysisPrompt', key: 'usedOpenAITokensToInputAnalysisPrompt' },
    { title: 'Output result', dataIndex: 'usedOpenAITokensToOutputAnalysisResults', key: 'usedOpenAITokensToOutputAnalysisResults' },
    {
        title: 'Total Input',
        key: 'input-tokens',
        render: (_, analysis) => <>{analysisInputTotalCost(analysis)}</>,
    },
    {
        title: 'Total Output',
        key: 'output-tokens',
        render: (_, analysis) => <>{analysisOutputTotalCost(analysis)}</>,
    },
];

export const AnalysesStatisticPage: React.FC = () => {
    const [dates, setDates] = useState<RangeValue>(null);
    const [analyses, setAnalyses] = useState<Analysis[]>([]);
    const [totalInputCost, setTotalInputCost] = useState<number>(0);
    const [totalOutputCost, setTotalOutputCost] = useState<number>(0);

    const loadAnalysesStatistic = useCallback((datesRange: RangeValue) => {
        const dateFormat = 'YYYY-MM-DD';
        const createdFrom = !datesRange || !datesRange[0] ? '' : dayjs.isDayjs(datesRange[0]) ? datesRange[0]?.format(dateFormat) : '';
        const createdTo = !datesRange || !datesRange[1] ? '' : dayjs.isDayjs(datesRange[1]) ? datesRange[1]?.format(dateFormat) : '';

        if (!createdFrom || !createdTo) {
            return;
        }

        statisticService
            .getAnalyses({ createdFrom, createdTo })
            .then((resp: Analysis[]) => {
                const allAnalyses = resp || [];
                setAnalyses(allAnalyses);
                setTotalInputCost(
                    allAnalyses.reduce((sum: number, analysis: Analysis) => {
                        return analysisInputTotalCost(analysis) + sum;
                    }, 0),
                );
                setTotalOutputCost(
                    allAnalyses.reduce((sum: number, analysis: Analysis) => {
                        return analysisOutputTotalCost(analysis) + sum;
                    }, 0),
                );
            })
            .catch(() => {
                setAnalyses([]);
                setTotalInputCost(0);
                setTotalOutputCost(0);
            });
    }, []);

    useEffect(() => {
        loadAnalysesStatistic(dates);
    }, [dates, loadAnalysesStatistic]);

    return (
        <div>
            <ConfigProvider theme={lightThemeConfig}>
                <Layout>
                    <div className="wide-fluid-content">
                        <Flex justify={'space-between'} align={'center'} gap={8} style={{ marginBottom: '1rem' }}>
                            <Flex align={'center'} gap={8}>
                                <div>Enter date range:</div>
                                <RangePicker
                                    value={dates}
                                    onChange={(value) => {
                                        setDates(value);
                                    }}
                                />
                            </Flex>
                            <Space>
                                <div>
                                    Input: <b>{tokensFormat(totalInputCost)}</b>
                                </div>
                                <div>
                                    Output: <b>{tokensFormat(totalOutputCost)}</b>
                                </div>
                            </Space>
                        </Flex>
                        <Table rowKey={'id'} columns={columns} dataSource={analyses} pagination={{ defaultPageSize: 100 }} />
                    </div>
                </Layout>
            </ConfigProvider>
        </div>
    );
};
