import { Tooltip } from 'antd';
import { SiteMapItem } from '../SiteMapItem';
import React from 'react';
import { Page } from '../../../services/pages/dto/page';
import { PageWithSplitUrl } from '../models/page-with-split-url';
import { TreeDataNode } from '../models/tree-data-node';

export default function convertSitemapIntoDataNodes(sitemapPages: Page[]): TreeDataNode[] {
    const dataNodes: TreeDataNode[] = [];
    const sortedPagesWithSplitUrl: PageWithSplitUrl[] = sitemapPages
        .map((page) => {
            return { ...page, splitUrl: splitUrlByLevels(page.url) };
        })
        .sort((a, b) => (a.splitUrl.length > b.splitUrl.length ? 1 : -1));

    const parentNodeSplitUrlLength = sortedPagesWithSplitUrl[0].splitUrl.length;

    sortedPagesWithSplitUrl.forEach((pageWithSplitUrl: PageWithSplitUrl) => {
        const { title, splitUrl } = pageWithSplitUrl;
        let siblingNodes = dataNodes;
        let parentDataNode: TreeDataNode | null = null;
        splitUrl.forEach((splitUrlNode, index) => {
            if (index < parentNodeSplitUrlLength - 1) {
                return;
            }
            let nodeWithSameUrl = siblingNodes.find(({ item }) => item.urlNode === splitUrlNode);
            if (!nodeWithSameUrl) {
                const isLastChild = index === splitUrl.length - 1;
                const nodeTitle = isLastChild ? title : '';
                nodeWithSameUrl = createDataNode(nodeTitle, splitUrlNode, (parentDataNode?.key || '0').toString(), siblingNodes.length, pageWithSplitUrl);
                siblingNodes.push(nodeWithSameUrl);
            }
            siblingNodes = nodeWithSameUrl.children as TreeDataNode[];
            parentDataNode = nodeWithSameUrl;
        });
    });

    return dataNodes;
}

function splitUrlByLevels(link: string): string[] {
    const splitUrl: string[] = [];
    try {
        const url: URL = new URL(link);
        splitUrl.push(url.origin);
        url.pathname
            .split('/')
            .filter((subUrl) => subUrl.length > 0)
            .forEach((subUrl) => splitUrl.push(subUrl));
        return splitUrl;
    } catch (e) {
        return splitUrl;
    }
}

function createDataNode(title: string, urlNode: string, parentKey: string = '0', siblingsCount: number = 0, pageWithSplitUrl: PageWithSplitUrl): TreeDataNode {
    const item = { urlNode, ...pageWithSplitUrl };
    const { urlStatusCode } = pageWithSplitUrl;
    return {
        key: `${parentKey}-${siblingsCount}`,
        item,
        title: (
            <Tooltip title={urlStatusCode === 200 ? title : ''}>
                <div>
                    <SiteMapItem itemProps={item} />
                </div>
            </Tooltip>
        ),
        children: [],
    };
}
