import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { Lookups } from '../services/lookups/dto/lookup.dto';
import { lookupsService } from '../services/lookups/lookups.service';
import { User, useUser } from './UserContext';

export const LookupsContext = React.createContext<Lookups | null>(null);

export const useLookups = () => {
    return useContext<Lookups | null>(LookupsContext);
};

export const LookupsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [lookups, setLookups] = useState<Lookups | null>(null);
    const user: User | null = useUser();

    useEffect(() => {
        if (!user){
            return;
        }

        lookupsService
            .getAll()
            .then((response) => {
                setLookups(response);
            })
            .catch(() => {
                setLookups(null);
            });
    }, [setLookups, user]);

    return <LookupsContext.Provider value={lookups}>{children}</LookupsContext.Provider>;
};
