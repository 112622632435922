import { theme, ThemeConfig } from 'antd';

export const primaryColor = '#1737d0';
export const headerHeight = 88;
const horizontalPadding = '3rem';

const sharedTokens = {
    colorPrimary: primaryColor,
    colorSplit: '#d9d9d9',
    colorLink: primaryColor,
    fontWeightStrong: 500,
    fontFamily:
        '"Noto Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
};

export const darkThemeConfig: ThemeConfig = {
    algorithm: theme.darkAlgorithm,
    token: {
        ...sharedTokens,
        colorText: '#ffffff',
    },
    components: {
        Layout: { headerBg: '#000000', headerPadding: `0 ${horizontalPadding}`, footerPadding: `1.5rem ${horizontalPadding}` },
        Button: { algorithm: true, colorLink: '#ffffff', colorLinkHover: 'rgba(255, 255, 255, 0.7)', paddingContentHorizontal: 4 },
        Input: { algorithm: true, colorBgBase: '#ffffff', colorText: 'rgba(0, 0, 0, 0.85)', colorTextPlaceholder: 'rgba(0, 0, 0, 0.25)', colorIcon: 'rgba(0, 0, 0, 0.45)' },
        Message: { contentPadding: '0.875rem 1rem', contentBg: 'rgba(255, 255, 255, 0.25)' },
        Menu: {
            collapsedWidth: 300,
            horizontalItemSelectedColor: 'black',
        },
    },
};

export const lightThemeConfig: ThemeConfig = {
    algorithm: theme.defaultAlgorithm,
    token: {
        ...sharedTokens,
        colorText: '#000000',
    },
    components: {
        Layout: {
            headerBg: '#ffffff',
            bodyBg: '#f5f5f5',
        },
        Menu: {
            collapsedWidth: 300,
            horizontalItemSelectedColor: '#ffffff',
            itemPaddingInline: 0,
        },
        Divider: {
            colorSplit: '#d9d9d9',
        },
    },
};
