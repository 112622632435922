import React, { useCallback, useEffect, useState } from 'react';
import { statisticService } from '../../../services/statistic/statisticService';
import { ConfigProvider, DatePicker, Flex, Layout, Table } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { lightThemeConfig } from '../../../components/Layouts/themeConfigs';
import { ColumnsType } from 'antd/es/table';
import { Feedback } from '../../../services/feedbacks/dto/feedback';

const { RangePicker } = DatePicker;
type RangeValue = [Dayjs | null, Dayjs | null] | null;

const columns: ColumnsType<Feedback> = [
    { title: 'Id', dataIndex: 'id', key: 'id' },
    { title: 'createdAt', dataIndex: 'createdAt', key: 'createdAt' },
    { title: 'Score', dataIndex: 'score', key: 'score' },
    { title: 'Message', dataIndex: 'message', key: 'message' },
    { title: 'User', dataIndex: 'userId', key: 'userId' },
    { title: 'Project', dataIndex: 'projectId', key: 'projectId' },
    { title: 'Analysis', dataIndex: 'analysisId', key: 'analysisId' },
];

export const FeedbackStatisticPage: React.FC = () => {
    const [dates, setDates] = useState<RangeValue>(null);
    const [feedbacks, setFeedbacks] = useState<Feedback[]>([]);

    const loadFeedbackStatistic = useCallback((datesRange: RangeValue) => {
        const dateFormat = 'YYYY-MM-DD';
        const createdFrom = !datesRange || !datesRange[0] ? '' : dayjs.isDayjs(datesRange[0]) ? datesRange[0]?.format(dateFormat) : '';
        const createdTo = !datesRange || !datesRange[1] ? '' : dayjs.isDayjs(datesRange[1]) ? datesRange[1]?.format(dateFormat) : '';

        if (!createdFrom || !createdTo) {
            return;
        }

        statisticService
            .getFeedbacks({ createdFrom, createdTo })
            .then((resp: Feedback[]) => {
                const allFeedbacks = resp || [];
                setFeedbacks(allFeedbacks);
            })
            .catch(() => {
                setFeedbacks([]);
            });
    }, []);

    useEffect(() => {
        loadFeedbackStatistic(dates);
    }, [dates, loadFeedbackStatistic]);

    return (
        <div>
            <ConfigProvider theme={lightThemeConfig}>
                <Layout>
                    <div className="wide-fluid-content">
                        <Flex justify={'space-between'} align={'center'} gap={8} style={{ marginBottom: '1rem' }}>
                            <Flex align={'center'} gap={8}>
                                <div>Enter date range:</div>
                                <RangePicker
                                    value={dates}
                                    onChange={(value) => {
                                        setDates(value);
                                    }}
                                />
                            </Flex>
                        </Flex>
                        <Table rowKey={'id'} columns={columns} dataSource={feedbacks} pagination={{ defaultPageSize: 100 }} />
                    </div>
                </Layout>
            </ConfigProvider>
        </div>
    );
};
