import { CredentialResponse, googleLogout } from '@react-oauth/google';
import { apiService, ApiService } from '../api/apiService';
import { AuthToken } from './dto/auth-token.dto';
import { UserDto } from './dto/userDto';

export class AuthService {
    constructor(private apiService: ApiService) {}

    login(user: UserDto): Promise<AuthToken> {
        return this.apiService.post('auth/login', { body: { email: user.email, password: user.password } });
    }

    registerUser(user: UserDto): Promise<AuthToken> {
        return this.apiService.post('auth/register-user', { body: { email: user.email, password: user.password } });
    }

    resetPassword(email: string): Promise<boolean> {
        return this.apiService.post('auth/reset-password', { body: { email: email } });
    }

    changePassword(password: string, token: string): Promise<boolean> {
        return this.apiService.patch('auth/change-password', { body: { password, token } });
    }

    logout(): Promise<boolean> {
        googleLogout();
        return this.apiService.get('auth/logout');
    }

    loginWithGoogle(userCredentials: CredentialResponse): Promise<AuthToken> {
        return this.apiService.post('auth/google/login', { body: { ...userCredentials } });
    }

    loginWithLinkedIn(code: string): Promise<AuthToken> {
        return this.apiService.post('auth/linkedin/login', { body: { code } });
    }

    confirmEmail(token: string) {
        return this.apiService.post('auth/confirm-email', { body: { token } });
    }

    resendConfirmEmail(token: string) {
        return this.apiService.post('auth/resend-confirmation-link', { body: { token } });
    }
}

export const authService = new AuthService(apiService);
