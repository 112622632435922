import React, { useMemo } from 'react';
import { User, useUser } from '../../../../context/UserContext';
import { Avatar } from 'antd';

export const UserAvatar: React.FC = () => {
    const user: User | null = useUser();
    const defaultUserInitials = 'U';

    const userInitials = useMemo<string>(() => {
        if (!user || !user.email) {
            return defaultUserInitials;
        }
        return user.email.slice(0, 1).toUpperCase();
    }, [user]);

    return <Avatar>{userInitials}</Avatar>;
};
