import React, { useCallback, useEffect, useState } from 'react';
import { Project, ProjectStatus } from '../../services/projects/dto/project';
import { Menu, Typography } from 'antd';
import { MenuItemType } from 'antd/es/menu/hooks/useItems';
import { FileOutlined, PlusOutlined, SyncOutlined } from '@ant-design/icons';
import { ProjectMenuItem } from './ProjectMenuItem';
const { Text } = Typography;

interface ProjectsMenuProps {
    projects: Project[];
    activeProjectId: number | null;
    onActiveProjectIdChanged: Function;
    onProjectDeleted: Function;
    showAddNewProject?: boolean;
    menuTitle?: string | undefined;
}

const NewProjectKey: string = 'new';

export const ProjectsMenu: React.FC<ProjectsMenuProps> = ({
    projects,
    activeProjectId,
    onActiveProjectIdChanged,
    onProjectDeleted,
    showAddNewProject = true,
    menuTitle = undefined,
}) => {
    const [menuItems, setMenuItems] = useState<MenuItemType[]>([]);

    const handleProjectSelection = useCallback(
        (item: { key: string }) => {
            if (item.key === NewProjectKey) {
                onActiveProjectIdChanged(null);
                return;
            }
            onActiveProjectIdChanged(item.key ? +item.key : null);
        },
        [onActiveProjectIdChanged],
    );

    const handleProjectDeleted = useCallback(
        (project: Project) => {
            onProjectDeleted(project.id);
        },
        [onProjectDeleted],
    );

    const getProjectMenuItems = useCallback(
        (projects: Project[]): MenuItemType[] => {
            return projects.map((project) => ({
                key: (project.id || NewProjectKey).toString(),
                label: <ProjectMenuItem project={project} onProjectDeleted={handleProjectDeleted} />,
                icon: project.status === ProjectStatus.Scanning || project.status === ProjectStatus.Analysing ? <SyncOutlined spin={true} /> : <FileOutlined />,
            }));
        },
        [handleProjectDeleted],
    );

    useEffect(() => {
        const items = getProjectMenuItems(projects);
        const addNewProjectMenuItem = {
            key: NewProjectKey,
            label: 'Add New Project',
            icon: <PlusOutlined />,
        };
        const additionalMenuItems = showAddNewProject ? [addNewProjectMenuItem] : [];
        setMenuItems([...additionalMenuItems, ...items]);
    }, [projects, getProjectMenuItems, showAddNewProject]);

    return (
        <div>
            {menuTitle && (
                <Text type="secondary" strong style={{ margin: '0 0 0.25rem 0.5rem', display: 'block' }}>
                    {menuTitle}
                </Text>
            )}
            <Menu inlineIndent={8} onClick={handleProjectSelection} selectedKeys={[(activeProjectId || NewProjectKey).toString()]} mode="inline" items={menuItems} />
        </div>
    );
};
