import { apiService, ApiService } from '../api/apiService';
import { PageAnalyseCost } from './dto/page-analyse-cost.dto';

export class ChargerService {
    constructor(private apiService: ApiService) {}

    getCostOfAnalyses(pageAnalyseCostDto: PageAnalyseCost): Promise<number> {
        const { pageId, analysesTypes, includeSubUrls } = pageAnalyseCostDto;
        return this.apiService.post('charger/cost-of-anlyse', { body: { pageId, analysesTypes, includeSubUrls } });
    }
}

export const chargerService = new ChargerService(apiService);
