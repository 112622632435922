import React from 'react';
import { Button, Col, Flex, Layout, Row, Typography } from 'antd';
import { Link } from 'react-router-dom';

const { Footer } = Layout;
const { Title, Text } = Typography;

export const AppFooter: React.FC = () => {
    return (
        <Footer>
            <Row align="top" justify="start">
                <Col>
                    <div>
                        <Title style={{ marginTop: '0', fontWeight: 600 }} level={3}>
                            PerfectSite.ai
                        </Title>
                    </div>
                    <Flex justify={'flex-start'} align={'flex-start'} gap={'middle'}>
                        <Text>Powered By</Text>
                        <a href={'https://www.door3.com/'} title="Door3" target="_blank" rel="noreferrer">
                            <img width={99} height={24} alt="Door3 logo" src="/images/door3-logo-white.svg" />
                        </a>
                    </Flex>
                </Col>
                <Col offset={2}>
                    <Flex justify={'flex-start'} align={'flex-start'} gap={'large'}>
                        <Link to={'/'}>
                            <Button type={'link'} className="strong-btn">
                                Home
                            </Button>
                        </Link>
                        <Link to={'/faq'}>
                            <Button type={'link'} className="strong-btn">
                                FAQ
                            </Button>
                        </Link>
                        <Link to={'/terms-and-conditions'}>
                            <Button type={'link'} className="strong-btn">
                                Terms &amp; Conditions
                            </Button>
                        </Link>
                        <Link to={'/privacy-policy'}>
                            <Button type={'link'} className="strong-btn">
                                Privacy Policy
                            </Button>
                        </Link>
                    </Flex>
                </Col>
            </Row>
            <Row align="bottom" justify="end">
                <Col>
                    <Text>Copyright 2023 DOOR3. All Rights Reserved.</Text>
                </Col>
            </Row>
        </Footer>
    );
};
