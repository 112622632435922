import React, { useMemo } from 'react';
import { Divider, Dropdown, MenuProps, Space } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { UserAvatar } from '../UserAvatar/UserAvatar';
import { User, UserRole, useUser } from '../../../../context/UserContext';
import { LogOutButton } from '../LogOutButton/LogOutButton';
import { Link } from 'react-router-dom';
import { EditGlobalPromptConfig } from '../../../EditGlobalPromptConfig/EditGlobalPromptConfig';

const logoutMenuItemWithDivider: MenuProps['items'] = [
    {
        type: 'divider',
    },
    {
        label: <LogOutButton />,
        key: 'logout-button',
        className: 'p-0',
    },
];

const analysisResultsMenuItemWithDivider: MenuProps['items'] = [
    {
        type: 'divider',
    },
    {
        label: <Link to={'/'}>Analysis Results</Link>,
        key: 'analysis-results',
    },
];

const editPromptMenuItemWithDivider: MenuProps['items'] = [
    {
        type: 'divider',
    },
    {
        label: <EditGlobalPromptConfig />,
        key: 'edit-global-prompt',
        className: 'p-0',
    },
];

const statisticsMenuItemWithDivider: MenuProps['items'] = [
    {
        type: 'divider',
    },
    {
        key: 'need-help-group',
        type: 'group',
        label: 'Statistics',
        children: [
            {
                key: 'statistic-analyses',
                label: <Link to={'/statistic/analyses'}>Analyses</Link>,
            },
            {
                key: 'statistic-feedbacks',
                label: <Link to={'/statistic/feedbacks'}>Feedbacks</Link>,
            },
        ],
    },
];

const needHelpMenuItems: MenuProps['items'] = [
    {
        type: 'divider',
    },
    {
        key: 'need-help-group',
        type: 'group',
        label: 'Need help?',
        children: [
            {
                key: 'help-faq',
                label: <Link to={'/faq'}>FAQ</Link>,
            },
            {
                key: 'help-terms',
                label: <Link to={'/terms-and-conditions'}>Terms &amp; Conditions</Link>,
            },
            {
                key: 'help-privacy',
                label: <Link to={'/privacy-policy'}>Privacy Policy</Link>,
            },
        ],
    },
];

const adminMenuItems = [...editPromptMenuItemWithDivider, ...statisticsMenuItemWithDivider];

export const UserMenu: React.FC = () => {
    const user: User | null = useUser();

    const dropdownMenuItems = useMemo<MenuProps['items']>(() => {
        const userInfoMenuItem = {
            label: (
                <>
                    {user?.email} {user?.role !== UserRole.FreeTrialUser ? `(${user?.role})` : ''}
                </>
            ),
            key: 'user-info',
            disabled: true,
        };
        const userRoleMenuItems = user?.role === UserRole.Admin ? adminMenuItems : [];
        return [userInfoMenuItem, ...analysisResultsMenuItemWithDivider, ...userRoleMenuItems, ...needHelpMenuItems, ...logoutMenuItemWithDivider];
    }, [user]);

    return (
        <Dropdown menu={{ items: dropdownMenuItems }}>
            <Space size={2}>
                <UserAvatar />
                <Divider />
                <CaretDownOutlined />
            </Space>
        </Dropdown>
    );
};
