import { apiService, ApiService } from '../api/apiService';
import { Page } from './dto/page';

export class PagesService {
    constructor(private apiService: ApiService) {}

    getAllPages(): Promise<Page[]> {
        return this.apiService.get('pages');
    }

    getPageById(id: number, signal?: AbortSignal): Promise<Page> {
        return this.apiService.get(`pages/${id}`, { signal });
    }

    getSubPagesById(id: number): Promise<Page[] | undefined> {
        return this.apiService.get(`pages/${id}/sub-pages`);
    }

    updatePage(page: Page): Promise<Page> {
        return this.apiService.patch(`pages/${page.id}`, { body: page });
    }
}

export const pagesService = new PagesService(apiService);
